// react component that generate a form item based on the type of the field and the field's name and label

import { FormItemProps } from '~/types';
import {
    Checkbox,
    Col,
    ColorPicker,
    DatePicker,
    Form,
    FormInstance,
    Input,
    Radio,
    Select,
    Space,
    Switch,
    TimePicker,
} from 'antd';
import GeoLocator from '~/components/form/GeoLocator.tsx';
import WeekDaysPicker from '~/components/form/components/WeekDaysPicker.tsx';
import MonthDaysPicker from '~/components/form/components/MonthDaysPicker.tsx';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface Props extends FormItemProps {
    form: FormInstance;
}

function RowItemBuilder(props: Props) {
    const { t } = useTranslation();
    const noLabelsTypes = ['switch', 'checkbox'];
    let item;
    switch (props.type) {
        case 'select':
            item = (
                <Select
                    mode={props.selectMode}
                    placeholder={t(props.placeholder || '')}
                    onChange={props.onChange}
                    allowClear
                >
                    {props.options?.map((option: any) => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>
            );
            break;

        case 'switch':
            item = (
                <Space size={'middle'}>
                    <Switch
                        onChange={(checked: boolean) => {
                            // props.form.setFieldValue(props.name, checked);
                            props.form.setFields([
                                {
                                    name: props.name,
                                    value: checked,
                                },
                            ]);
                        }}
                    />
                    {t(props.label)}
                </Space>
            );
            break;
        case 'textarea':
            item = <Input.TextArea placeholder={t(props.placeholder || '')} />;
            break;
        case 'radio':
            item = (
                <Radio.Group
                    value={
                        props.options && props.options?.length > 0
                            ? props.options[0].value
                            : undefined
                    }
                >
                    <Space direction={props.optionsVertical ? 'vertical' : undefined}>
                        {props.options?.map((option: any) => (
                            <Radio key={option.value} value={option.value}>
                                {option.label}
                            </Radio>
                        ))}
                    </Space>
                </Radio.Group>
            );
            break;
        case 'checkbox':
            item = (
                <Space size={'middle'}>
                    <Checkbox
                        onChange={(e) => {
                            props.form.setFieldValue(props.name, e.target.checked);
                        }}
                    />
                    {t(props.label)}
                </Space>
            );
            break;
        case 'upload':
            // TODO
            // item = <Upload></Upload>;
            item = <Input type={'file'} />;
            break;
        case 'geoLocator':
            return <GeoLocator form={props.form} />;
        case 'date':
            item = <DatePicker style={{ width: '100%' }} />;
            break;
        case 'time':
            item = <TimePicker style={{ width: '100%' }} />;
            break;
        case 'weekDaysSelector':
            item = <WeekDaysPicker name={props.name} form={props.form} />;
            break;
        case 'monthDaysSelector':
            item = <MonthDaysPicker name={props.name} form={props.form} />;
            break;
        case 'color-picker':
            item = (
                <ColorPicker
                    showText={(color) => <span>Custom Text ({color.toHexString()})</span>}
                    onChange={(_, hex) => {
                        props.form.setFieldValue(props.name, hex);
                    }}
                />
            );
            break;
        default:
            item = <Input placeholder={t(props.placeholder || '')} type={props.type} />;
    }

    return (
        <Col {...props.gridProps} span={props.gridProps?.span || 24}>
            {' '}
            <Form.Item
                name={props.name}
                label={!noLabelsTypes.includes(props.type) && t(props.label)}
                rules={props.rules}
            >
                {item}
            </Form.Item>
        </Col>
    );
}

export default RowItemBuilder;
