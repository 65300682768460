import { getFromStorage } from '~/helpers/miscHelper.ts';
import { Roles, StorageTokenKey } from '~/config/constants.ts';
import { IUser } from '~/services/api.ts';
import { Constants } from '~/config';

export function tokenHelper() {
    return {};
}

// export function saveAccessToken(token: string) {}

export function getToken() {
    return getFromStorage(StorageTokenKey);
}

export function removeAccessToken() {
    localStorage.removeItem(StorageTokenKey);
}

export function isAdminUser(data?: IUser) {
    if (!data === undefined) {
        return false;
    }
    const adminRoles = [Constants.Roles.Admin, Constants.Roles.Sup];
    return adminRoles.includes(data?.roles[0].name as Roles);
}

export function hasPermission(data: IUser, permission: string) {
    const permissions = data?.permissions;
    if (!permissions) {
        return false;
    }
    return permissions.map((perm) => perm.name).includes(permission);
}