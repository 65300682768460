import { Modal } from 'antd';
import React from 'react';

const { confirm } = Modal;

const showPromiseConfirm = (
    icon: React.ReactNode,
    title: string,
    content: string,
    successAction: any,
) => {
    confirm({
        title: title,
        icon: icon,
        content: content,
        async onOk() {
            try {
                return await new Promise((resolve) => {
                    setTimeout(async () => {
                        try {
                            await successAction();
                        } finally {
                            resolve(true);
                        }
                    }, 1000);
                });
            } catch {
                return console.log('Oops errors!');
            }
        },
        onCancel() {},
    });
};

export { showPromiseConfirm };