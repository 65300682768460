import { Modal, ModalProps } from 'antd';

interface Props extends ModalProps {}

function FsModal(props: Props) {
    return (
        <Modal
            {...props}
            okButtonProps={{
                hidden: !props.onOk,
                ...props.okButtonProps,
            }}
            cancelButtonProps={{
                hidden: true,
                ...props.cancelButtonProps,
            }}
        >
            <div
                style={{
                    paddingTop: 50,
                }}
            >
                {props.open ? props.children : <></>}
            </div>
        </Modal>
    );
}

export default FsModal;
