import { useState } from 'react';
import { message } from 'antd';
import { enhancedApi } from '~/services/enhancedApi.ts';
import { useAuth } from '~/hooks';
import { useTranslation } from 'react-i18next';
import { FormItemProps, TableColumn } from '~/types';
import { showPromiseConfirm } from '~/helpers/confirm.ts';
import { DeleteOutlined, EditOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import FsModal from '~/components/modal';
import FsFormBuilder from '~/templates/FormGenerator';
import { SiteData } from '~/types/querie.ts';
import Loading from '~/components/Loading.tsx';
import FsListView from '~/templates/ListView';
import { DefaultPageSize, DefaultPageSizeOptions, Permissions } from '~/config/constants.ts';
import { useDeleteMultiSiteMutation } from '~/services/api.ts';

function Sites() {
    const [openAddSiteDrawer, setOpenAddSiteDrawer] = useState(false);
    const [selectedSite, setSelectedSite] = useState<any>(null);
    const [selectedSites, setSelectedSites] = useState<any[]>([]);
    const [deleteMultiSitesHandler, { isLoading: isMultiDeleteLoading }] =
        useDeleteMultiSiteMutation();
    const [messageApi, contextHolder] = message.useMessage();
    const { data, isFetching, isLoading } = enhancedApi.useGetSitesQuery();
    const { data: clients } = enhancedApi.useGetClientsQuery();
    const { currentUserHasPermission } = useAuth();

    const [updateSiteHandler, { isLoading: isUpdateLoading }] = enhancedApi.useUpdateSiteMutation();
    const [addSiteHandler, { isLoading: isCreateLoading }] = enhancedApi.useCreateSiteMutation();
    const [deleteSiteHandler] = enhancedApi.useDeleteSiteMutation();

    const { t } = useTranslation();

    const columns: TableColumn[] = [
        {
            title: 'site',
            dataIndex: 'name',
            subIndex: 'surname',
            imgIndex: 'img',
            type: 'avatar',
            width: '32',
        },
        {
            title: 'client',
            dataIndex: 'client',
            type: 'text',
        },
        {
            title: 'address',
            dataIndex: 'address',
            type: 'text',
        },
    ];

    const addSiteFormItems: FormItemProps[] = [
        {
            name: 'name',
            label: 'name',
            placeholder: 'enter_site_name',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_site_name',
                },
            ],
            gridProps: {
                span: 10,
            },
        },
        {
            name: 'client_id',
            label: 'client',
            placeholder: 'enter_site_client',
            type: 'select',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_site_client',
                },
            ],
            options: clients?.map((client: any) => {
                return {
                    label: client.name,
                    //TODO update
                    value: client.id,
                };
            }),
            gridProps: {
                span: 14,
            },
        },
        {
            type: 'geoLocator',
            label: '',
            name: '',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_site_address',
                },
            ],
        },
        {
            name: 'address',
            label: 'address',
            placeholder: 'enter_site_address',
            type: 'textarea',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_site_address',
                },
            ],
        },
        {
            name: 'comment',
            label: 'comment',
            placeholder: 'enter_site_comment',
            type: 'textarea',
            rules: [
                {
                    required: false,
                },
            ],
        },
        {
            name: 'color',
            label: 'color',
            placeholder: 'enter_site_color',
            type: 'color-picker',
            rules: [
                {
                    required: false,
                },
            ],
        },
    ];

    const deleteSite = (id: number) => {
        showPromiseConfirm(
            <ExclamationCircleFilled />,
            t('are_you_sure'),
            t('this_action_can_not_be_undone'),
            () => {
                deleteSiteHandler({ id })
                    .unwrap()
                    .then(() => {
                        messageApi.success(t('deleted_successfully'));
                    });
            },
        );
    };
    const deleteMultiSites = (ids: number[]) => {
        showPromiseConfirm(
            <ExclamationCircleFilled />,
            t('are_you_sure'),
            t('this_action_can_not_be_undone'),
            () => {
                deleteMultiSitesHandler({
                    body: {
                        Ids: ids,
                    },
                })
                    .unwrap()
                    .then((response: any) => {
                        response.message && messageApi.success(t('deleted_successfully'));
                    });
            },
        );
    };
    return (
        <div>
            {contextHolder}
            <FsModal
                open={openAddSiteDrawer}
                onCancel={() => {
                    setOpenAddSiteDrawer(false);
                    setSelectedSite(null);
                }}
                title={t(selectedSite ? 'update_site' : 'add_site')}
                width={'80%'}
            >
                <FsFormBuilder
                    initialValues={selectedSite}
                    onSubmit={(data: SiteData) => {
                        selectedSite
                            ? updateSiteHandler({
                                  id: selectedSite.id,
                                  body: data,
                              })
                                  .unwrap()
                                  .then((response: any) => {
                                      response.message &&
                                          messageApi.success(t('updated_successfully'));
                                  })
                                  .finally(() => setOpenAddSiteDrawer(false))
                            : addSiteHandler({ body: data })
                                  .unwrap()
                                  .then((response: any) => {
                                      response.message &&
                                          messageApi.success(t('created_successfully'));
                                  })
                                  .finally(() => setOpenAddSiteDrawer(false));
                    }}
                    items={addSiteFormItems}
                    loading={isCreateLoading || isUpdateLoading}
                />
            </FsModal>
            {isLoading ? (
                <Loading />
            ) : (
                <FsListView
                    setSelectedRows={setSelectedSites}
                    enableMultiSelect={true}
                    isLoading={isFetching}
                    data={
                        (data &&
                            data.map((item: any) => ({
                                ...item,
                                //TODO
                                client: item.client.name,
                                img: 'https://picsum.photos/200',
                            }))) ||
                        []
                    }
                    columns={columns}
                    cardTitle={'Sites'}
                    pageActions={[
                        {
                            hasPermission: currentUserHasPermission(Permissions.CreateSite),
                            label: 'add_site',
                            btnType: 'primary',
                            onClick: () => {
                                setOpenAddSiteDrawer(true);
                            },
                            viewType: 'button',
                        },
                        {
                            label: 'actions',
                            hasPermission: currentUserHasPermission(Permissions.CreateAdmin),
                            btnType: 'primary',
                            multiSelectAction: true,
                            menuProps: {
                                items: [
                                    {
                                        key: 'delete',
                                        danger: true,
                                        icon: <DeleteOutlined />,
                                        label: t('delete_selected_sites'),
                                        onClick: () => {
                                            deleteMultiSites(selectedSites);
                                        },
                                    },
                                ],
                            },
                            viewType: 'list',
                        },
                    ]}
                    rowActions={[
                        {
                            type: 'list',
                            onClick: (item, key) => {
                                switch (key) {
                                    case 'edit':
                                        setSelectedSite(item);
                                        setOpenAddSiteDrawer(true);
                                        break;
                                    case 'delete':
                                        deleteSite(item.id);
                                        break;
                                }
                            },
                            listActions: [
                                {
                                    label: 'edit',
                                    key: 'edit',
                                    hasPermission: currentUserHasPermission(Permissions.EditSite),
                                    icon: <EditOutlined />,
                                },
                                {
                                    label: 'delete',
                                    danger: true,
                                    hasPermission: currentUserHasPermission(Permissions.DeleteSite),
                                    key: 'delete',
                                    icon: <DeleteOutlined />,
                                },
                            ],
                        },
                    ]}
                    paginationConfig={{
                        position: ['bottomCenter'],
                        defaultPageSize: DefaultPageSize,
                        showSizeChanger: true,
                        pageSizeOptions: DefaultPageSizeOptions,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        total: data?.length || 0,
                    }}
                    search={{
                        placeholder: 'search_placeholder',
                        onSearch: (value) => {
                            //TODO frontend search
                            alert(value);
                        },
                    }}
                />
            )}
        </div>
    );
}

export default Sites;
