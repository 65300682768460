import GoogleMapReact from 'google-map-react';
import { useEffect, useState } from 'react';
import marker from '../../assets/images/location-pin.png';

interface Props {
    onChange: (lat: string, lng: string) => void;
    lat: string;
    lng: string;
    height?: string;
    width?: string;
}

const Picker = () => (
    <div>
        <img src={marker} alt={'pin'} width={'30px'} />
    </div>
);
export default function FsMap({ onChange, lat, lng, height, width }: Props) {
    const [center, setCenter] = useState({
        lat: lat,
        lng: lng,
    });
    useEffect(() => {
        onChange(center.lat, center.lng);
    }, [center]);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return (
        // Important! Always set the container height explicitly
        <div style={{ height: height || '100%', width: width || '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: import.meta.env.VITE_GOOGLE_MAPS_KEY }}
                defaultCenter={{
                    lat: lat ? parseInt(lat) : 0,
                    lng: lng ? parseInt(lng) : 0,
                }}
                defaultZoom={8}
                onChange={(e) => {
                    setCenter({
                        lat: e.center.lat.toString(),
                        lng: e.center.lng.toString(),
                    });
                }}
                center={{
                    lat: lat ? parseInt(lat) : 0,
                    lng: lng ? parseInt(lng) : 0,
                }}
                onClick={(e) => {
                    setCenter({
                        lat: e.lat.toString(),
                        lng: e.lng.toString(),
                    });
                }}
            >
                <Picker />
            </GoogleMapReact>
        </div>
    );
}