import FsFormBuilder from '~/templates/FormGenerator';
import {
    useGetConnectedUserQuery,
    useLazyGetAdminQuery,
    useLazyGetAgentQuery,
    useUpdateAdminMutation,
    useUpdateAgentMutation,
} from '~/services/api.ts';
import { FormItemProps } from '~/types';
import { useEffect, useState } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import Loading from '~/components/Loading.tsx';

function UpdateProfile() {
    const [profile, setProfile] = useState(undefined);

    const [messageApi, contextHolder] = message.useMessage();
    const { t } = useTranslation();
    const { data } = useGetConnectedUserQuery();
    const [updateAdmin, { isLoading: isAdminUpdate }] = useUpdateAdminMutation();
    const [getAdminData] = useLazyGetAdminQuery();

    const [updateAgent, { isLoading: isAgentUpdate }] = useUpdateAgentMutation();
    const [getAgentData] = useLazyGetAgentQuery();

    const { updateProfile, getProfile, formItems } = (function loadProfileProps(): {
        updateProfile: any;
        getProfile: any;
        formItems: FormItemProps[];
    } {
        if (data?.roles && data.roles.length > 0) {
            switch (data?.roles[0].name) {
                case 'admin':
                    return {
                        updateProfile: updateAdmin,
                        getProfile: getAdminData,
                        formItems: [
                            {
                                name: 'name',
                                placeholder: 'enter_admin_name',
                                type: 'text',
                                rules: [
                                    {
                                        required: true,
                                        message: 'validate_enter_admin_name',
                                    },
                                ],
                                label: 'name',
                                gridProps: {
                                    lg: {
                                        span: 12,
                                    },
                                },
                            },
                            {
                                name: 'surname',
                                placeholder: 'enter_admin_surname',
                                type: 'text',
                                rules: [
                                    {
                                        required: true,
                                        message: 'validate_enter_admin_surname',
                                    },
                                ],
                                label: 'surname',
                                gridProps: {
                                    lg: {
                                        span: 12,
                                    },
                                },
                            },
                            {
                                name: 'email',
                                placeholder: 'enter_admin_email',
                                type: 'email',
                                rules: [
                                    {
                                        required: true,
                                        message: 'validate_enter_admin_email',
                                        pattern: new RegExp(
                                            '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$',
                                        ),
                                    },
                                ],
                                label: 'email',
                                disabled: () => true,
                            },
                            {
                                name: 'birthdate',
                                placeholder: 'enter_admin_birthdate',
                                type: 'date',
                                rules: [
                                    {
                                        required: true,
                                        message: 'validate_enter_admin_birthdate',
                                    },
                                ],
                                label: 'birthdate',
                            },
                            {
                                name: 'gender',
                                type: 'radio',
                                label: 'gender',
                                rules: [
                                    {
                                        required: true,
                                        message: 'validate_enter_admin_gender',
                                    },
                                ],
                                options: [
                                    {
                                        value: 1,
                                        label: t('male'),
                                    },
                                    {
                                        value: 2,
                                        label: t('female'),
                                    },
                                ],
                            },
                        ],
                    };

                case 'agent':
                    return {
                        updateProfile: updateAgent,
                        getProfile: getAgentData,
                        formItems: [
                            {
                                name: 'name',
                                placeholder: 'enter_agent_name',
                                type: 'text',
                                rules: [
                                    {
                                        required: true,
                                        message: 'validate_enter_agent_name',
                                    },
                                ],
                                label: 'name',
                                gridProps: {
                                    span: 12,
                                },
                            },
                            {
                                name: 'surname',
                                placeholder: 'enter_agent_surname',
                                type: 'text',
                                rules: [
                                    {
                                        required: true,
                                        message: 'validate_enter_agent_surname',
                                    },
                                ],
                                label: 'surname',
                                gridProps: {
                                    span: 12,
                                },
                            },
                            {
                                name: 'email',
                                placeholder: 'enter_agent_email',
                                type: 'email',
                                rules: [
                                    {
                                        required: true,
                                        message: 'validate_enter_agent_email',
                                        pattern: new RegExp(
                                            '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$',
                                        ),
                                    },
                                ],
                                label: 'email',
                                disabled: () => true,
                            },
                            {
                                name: 'birthdate',
                                placeholder: 'enter_agent_birthdate',
                                type: 'date',
                                rules: [
                                    {
                                        required: true,
                                        message: 'validate_enter_agent_birthdate',
                                    },
                                ],
                                label: 'birthdate',
                            },
                            {
                                name: 'gender',
                                type: 'radio',
                                label: 'gender',
                                rules: [
                                    {
                                        required: true,
                                        message: 'validate_enter_agent_gender',
                                    },
                                ],
                                options: [
                                    {
                                        value: 1,
                                        label: t('male'),
                                    },
                                    {
                                        value: 2,
                                        label: t('female'),
                                    },
                                ],
                            },
                        ],
                    };

                // case 'superAdmin':
                //     return {
                //         updateProfile: updateAdmin,
                //         getProfile: getAdminData,
                //     };
            }
        }
        return {
            updateProfile: null,
            getProfile: null,
            formItems: [],
        };
    })();

    useEffect(() => {
        getProfile({ id: data?.id }).then((res: any) => {
            setProfile(res.data);
        });
    }, [data?.id, getProfile]);

    function updateUserProfile(values: any) {
        updateProfile({ id: data?.id, body: values }).then((res: any) => {
            !res.error && messageApi.success(t('updated_successfully'));
        });
    }

    return (
        <>
            {contextHolder}
            {profile ? (
                <FsFormBuilder
                    submitText={'confirm'}
                    initialValues={profile}
                    items={formItems}
                    onSubmit={updateUserProfile}
                    loading={isAdminUpdate || isAgentUpdate}
                />
            ) : (
                <Loading />
            )}
        </>
    );
}

export default UpdateProfile;