import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './config/lang';
import { ConfigProvider, theme } from 'antd';
import 'antd/dist/reset.css';
import RestoreAccount from '~/pages/auth/RestoreAccount.tsx';
import Home from '~/pages/home';
import Login from '~/pages/auth/Login.tsx';
import { useSelector } from 'react-redux';

function App(): React.JSX.Element {
    const isDarkMode = useSelector((state: { isDarkMode: boolean }) => state.isDarkMode);

    return (
        <>
            <ConfigProvider
                theme={{
                    algorithm: isDarkMode ? theme.darkAlgorithm : theme.compactAlgorithm,
                    components: {
                        Table: {
                            // headerBg: '#fff',
                        },
                    },
                }}
            >
                <Routes>
                    <Route path={'/login'} element={<Login />} />
                    <Route path={'/restore'} element={<RestoreAccount />} />
                    <Route path={'*'} element={<Home />} />
                </Routes>
            </ConfigProvider>
        </>
    );
}

export default App;
