import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { Middleware } from "@reduxjs/toolkit";
import { notification } from "antd";

/**
 * Log a warning and show a toast!
 */
export const errorsMiddleware: Middleware = () => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action) && action.payload.status !== 401) {
        // If we receive any error except 401 (we handle that one on auth middleware) response for any call, we show the error notification.
        // /!\ Do not show Status on PROD /!\
        notification.error({ message: action.payload.status + " - " + action.payload.data.message });
    }

    if (isRejectedWithValue(action) && action.payload.status === 503) {
        // If we receive an error 503 we redirect to show the maintenance page.
        window.location.href = "/maintenance";
    }
    return next(action);
};
