import { Button, Form, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemProps, ObjectAny } from '~/types';
import RowItemBuilder from '~/templates/FormGenerator/RowItemBuilder.tsx';
import { useEffect } from 'react';
import { FieldData } from 'rc-field-form/lib/interface';

interface Props {
    items: FormItemProps[];
    submitText?: string;
    loading?: boolean;
    onSubmit: (values: any) => void;
    initialValues?: ObjectAny;
    onFieldsChange?: (fields: FieldData[]) => void;
}

const FsFormBuilder = ({
    onSubmit,
    items,
    submitText,
    loading,
    initialValues,
    onFieldsChange,
}: Props) => {
    const [form] = Form.useForm();

    const { t } = useTranslation();

    function submit(values: any) {
        onSubmit(values);
    }

    function reset() {
        form.resetFields();
    }

    useEffect(() => {
        for (const initialValuesKey in initialValues) {
            form.setFieldValue(initialValuesKey, initialValues[initialValuesKey]);
        }
    }, [initialValues, form]);

    return (
        <Form
            form={form}
            name='control-hooks'
            onFinish={submit}
            layout='vertical'
            className='row-col'
            style={{ width: '100%' }}
            onFieldsChange={onFieldsChange}
        >
            <Space direction={'vertical'} style={{ width: '100%' }} size={'large'}>
                <Row gutter={[16, 24]}>
                    {items?.map((item: FormItemProps) => (
                        <RowItemBuilder key={item.name} {...item} form={form} />
                    ))}
                </Row>
                <Row justify={'end'}>
                    <Form.Item>
                        <Button
                            type='text'
                            onClick={reset}
                            style={{
                                border: 'none',
                                boxShadow: 'none',
                                fontStyle: 'italic',
                                textTransform: 'uppercase',
                            }}
                            className={'text-muted mx-10'}
                        >
                            {t('reset')}
                        </Button>
                        <Button
                            type='primary'
                            htmlType='submit'
                            loading={loading}
                            style={{ textTransform: 'uppercase' }}
                        >
                            {t(submitText || 'submit')}
                        </Button>
                    </Form.Item>
                </Row>
            </Space>
        </Form>
    );
};

export default FsFormBuilder;