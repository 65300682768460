import { Col, Row, Spin } from 'antd';

function Loading({ size }: { size?: 'small' | 'default' | 'large' }) {
    return (
        <Row
            justify={'center'}
            style={{
                marginTop: '20%',
            }}
        >
            <Col>
                <Spin size={size} />
            </Col>
        </Row>
    );
}

export default Loading;