import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';

import { Provider } from 'react-redux';
import { store } from '~/redux/store.ts';

import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from '~/pages/ErrorPage/ErrorBoundary.js';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense(import.meta.env.VITE_SF_KEY);
ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <Provider store={store}>
            {/*<DevSupport ComponentPreviews={ComponentPreviews} useInitialHook={useInitial}>*/}
            <ErrorBoundary>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </ErrorBoundary>
            {/*</DevSupport>*/}
        </Provider>
    </React.StrictMode>,
);
