import {
    Button,
    Card,
    Col,
    Dropdown,
    Empty,
    Form,
    Input,
    Row,
    Space,
    Table,
    TableProps,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { ObjectAny, PageAction, RowAction, TableColumn } from '~/types';
import rowsBuilder from '~/templates/ListView/RowsBuilder.tsx';
import type { TablePaginationConfig } from 'antd/es/table';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import FiltersBuilder from '~/templates/ListView/FiltersBuilder.tsx';
import { useState } from 'react';

/*
TODO:
    - Add filters
    - Add select row
    - Add select all rows
    - make column text act like link
 */

interface FsListViewProps extends TableProps<any> {
    data: any[];
    cardTitle: string;
    columns: TableColumn[];
    rowActions?: RowAction[];
    pageActions?: PageAction[];
    isLoading: boolean;
    paginationConfig?: TablePaginationConfig;
    search?: {
        placeholder?: string;
        onSearch: (value: string) => void;
    };
    setSelectedRows?: (items: any[]) => void;
    onRowClick?: (item: ObjectAny, key?: string) => void;
    enableMultiSelect?: boolean;
}

/**
 * JSX Component for displaying selected images in a fullscreen Modal
 * @param cardTitle
 * @param pageActions
 * @param data
 * @param rowActions
 * @param search
 * @param onRowClick
 * @param setSelectedRows
 * @param enableMultiSelect
 * @param {object} props
 * @param {boolean} props.isLoading state variable boolean for controling modal visibility
 * @param {paginationConfig} props.paginationConfig callback to state function to set modalVis
 * @param {string} props.columns string uri of the image to be displayed
 * @returns
 */
const FsListView = ({
    cardTitle,
    pageActions,
    columns,
    data,
    rowActions,
    isLoading,
    paginationConfig,
    onRowClick,
    search,
    setSelectedRows,
    enableMultiSelect,
    ...props
}: FsListViewProps) => {
    const { t } = useTranslation();
    const [multiRowsSelected, setMultiRowsSelected] = useState(false);

    return (
        <div className='tabled'>
            <Row gutter={24}>
                <Col xs={24}>
                    <Card
                        bordered={false}
                        className='criclebox tablespace mb-24'
                        title={t(cardTitle)}
                        extra={
                            pageActions &&
                            pageActions.length > 0 && (
                                <Space>
                                    {pageActions
                                        .filter((action) => action.hasPermission)
                                        .map((action, index) => {
                                            if (action.multiSelectAction && !multiRowsSelected) {
                                                return <></>;
                                            }
                                            switch (action.viewType) {
                                                case 'list': {
                                                    return (
                                                        <Dropdown menu={action.menuProps}>
                                                            <Button>
                                                                <Space>
                                                                    {t(action.label)}
                                                                    <DownOutlined size={2} />
                                                                </Space>
                                                            </Button>
                                                        </Dropdown>
                                                    );
                                                }
                                                default:
                                                    return (
                                                        <Button
                                                            key={index}
                                                            type={action.btnType || 'primary'}
                                                            className={`tag-${
                                                                action.btnType || 'primary'
                                                            }`}
                                                            onClick={action.onClick}
                                                        >
                                                            {t(action.label)}
                                                        </Button>
                                                    );
                                            }
                                        })}
                                </Space>
                            )
                        }
                    >
                        <div className='table-responsive'>
                            <Row
                                style={{
                                    margin: '8px 20px',
                                }}
                                justify={'space-between'}
                            >
                                <Space>
                                    <FiltersBuilder />
                                </Space>
                                <div>
                                    {search && (
                                        <Form
                                            onSubmitCapture={(event: any) => {
                                                const value = event.target[0].value;
                                                search?.onSearch(value);
                                            }}
                                        >
                                            <Input
                                                className='header-search'
                                                placeholder={t(
                                                    search?.placeholder || 'search_placeholder',
                                                )}
                                                prefix={<SearchOutlined />}
                                            />
                                        </Form>
                                    )}
                                </div>
                            </Row>

                            {data.length > 0 ? (
                                <Table
                                    {...props}
                                    rowSelection={
                                        enableMultiSelect
                                            ? {
                                                  type: 'checkbox',
                                                  onChange: (a: any[]) => {
                                                      setMultiRowsSelected(a.length > 0);
                                                      setSelectedRows &&
                                                          setSelectedRows(
                                                              a.map((key) => data[key].id),
                                                          );
                                                  },
                                              }
                                            : undefined
                                    }
                                    virtual={false}
                                    loading={isLoading}
                                    columns={columns.map((column) => {
                                        return {
                                            key: column.dataIndex,
                                            ...column,
                                            filters: column.filters,
                                            title: t(column.title).toUpperCase(),
                                        };
                                    })}
                                    dataSource={rowsBuilder({
                                        data,
                                        columns,
                                        rowActions,
                                        onRowClick,
                                        t,
                                    })}
                                    pagination={paginationConfig}
                                    className='ant-border-space'
                                />
                            ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )}
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default FsListView;
