import { ObjectAny, TableColumn } from '~/types';
import { Avatar, Badge, Button, Space, Typography } from 'antd';
import FsSwitch from '~/components/buttons/FsSwitch.tsx';
import { NavLink } from 'react-router-dom';

const { Title } = Typography;

interface Props {
    columnDesc: TableColumn;
    data: { [key: string]: any };
    onRowClick?: (item: ObjectAny, key?: string) => void;
}

function ColumnBuilder({ columnDesc, data, onRowClick }: Props) {
    switch (columnDesc.type) {
        case 'custom': {
            return columnDesc.renderer ? columnDesc.renderer(data) : <></>;
        }
        case 'avatar': {
            return (
                <Space size={'large'}>
                    <Avatar
                        className='shape-avatar'
                        shape='square'
                        size={40}
                        src={columnDesc.imgIndex ? data[columnDesc.imgIndex] : ''}
                    />

                    {columnDesc.dataIndex && (
                        <div>
                            {columnDesc.linkIndex ? (
                                <NavLink to={`${data[columnDesc.linkIndex]}`}>
                                    <Title style={{ marginBottom: '0' }} level={4}>
                                        {data[columnDesc.dataIndex]}
                                    </Title>
                                </NavLink>
                            ) : (
                                <Title
                                    onClick={() => {
                                        onRowClick && onRowClick(data);
                                    }}
                                    style={{
                                        marginBottom: '0',
                                        cursor: onRowClick ? 'pointer' : '',
                                    }}
                                    level={4}
                                >
                                    {data[columnDesc.dataIndex]}
                                </Title>
                            )}

                            {columnDesc.subIndex && (
                                <p style={{ marginBottom: '0' }}>{data[columnDesc.subIndex]}</p>
                            )}
                        </div>
                    )}
                </Space>
            );
        }
        case 'button':
            return (
                <Button
                    type={columnDesc.btnData?.type || 'primary'}
                    className='tag-primary'
                    onClick={() => columnDesc.btnData?.onClick(data)}
                >
                    {columnDesc.btnData?.label || 'ACTION'}
                </Button>
            );
        case 'switch': {
            return (
                <FsSwitch
                    defaultChecked
                    loading={columnDesc.switchData?.loading}
                    checked={columnDesc.switchData?.isChecked(data)}
                    onChange={(checked) => columnDesc.switchData?.onChange(checked, data)}
                />
            );
        }
        case 'date': {
            const date = (date: string) => {
                const d = new Date(date);
                return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
            };
            const d = data[columnDesc.dataIndex];

            const value = columnDesc.renderer ? columnDesc.renderer(d) : date(d);
            return (
                <div className='ant-employed'>
                    <Title style={{ marginBottom: '0' }} level={5}>
                        {value || '/'}
                    </Title>
                    {columnDesc.subIndex && (
                        <p style={{ marginBottom: '0' }}>{data[columnDesc.subIndex]}</p>
                    )}
                </div>
            );
        }
        case 'badge': {
            return (
                <Badge
                    text={columnDesc.subIndex && data[columnDesc.subIndex]}
                    status={data[columnDesc.dataIndex] ? 'success' : 'error'}
                />
            );
        }

        default: {
            const d = data[columnDesc.dataIndex];
            const value = columnDesc.renderer ? columnDesc.renderer(d) : d;
            return (
                <div>
                    <Title style={{ marginBottom: '0' }} level={5}>
                        {value || '/'}
                    </Title>
                    {columnDesc.subIndex && (
                        <p style={{ marginBottom: '0' }}>{data[columnDesc.subIndex]}</p>
                    )}
                </div>
            );
        }
    }
}

export default ColumnBuilder;
