import { Layout, theme } from 'antd';
import Header from '~/layouts/components/Header.tsx';
import { useState } from 'react';
import { Props } from '~/layouts/index.tsx';

const { Header: AntHeader, Content } = Layout;
export default function AgentLayout({ children }: Props) {
    const [collapsed, setCollapsed] = useState(false);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Layout
            style={{
                minHeight: '100vh',
            }}
        >
            <Layout>
                <AntHeader
                    style={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: colorBgContainer,
                    }}
                >
                    <Header
                        collapsed={collapsed}
                        onExpand={() => {
                            setCollapsed(!collapsed);
                        }}
                    />
                </AntHeader>
                <Content
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
}
