import { Button, ButtonProps } from 'antd';

interface Props extends ButtonProps {
    transparent?: boolean;
    noBorder?: boolean;
}

const FsButton = (props: Props) => {
    return (
        <Button
            {...props}
            style={{
                background: props.transparent ? 'transparent' : '',
                border: !props.noBorder ? '' : 'none',
                boxShadow: !props.noBorder ? '' : 'none',
            }}
        >
            {props.children}
        </Button>
    );
};

export default FsButton;
