import { Col, Flex, Row, Typography } from 'antd';
import errorImage from '../../assets/images/error.png';

type Props = {
    error: string;
};
const { Title } = Typography;
const ErrorPage = (props: Props) => {
    return (
        <Flex
            style={{
                height: '100vh',
            }}
            justify={'center'}
            align={'center'}
            vertical
            gap={'large'}
        >
            <Row justify={'center'}>
                <Col>
                    <img src={errorImage} alt={'error_av'} />
                </Col>
            </Row>
            <Row justify={'center'}>
                <Col>
                    <Title>Something Went Wrong: {props.error}</Title>
                </Col>
            </Row>
        </Flex>
    );
};

export default ErrorPage;
