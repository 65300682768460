import { Route, Routes } from 'react-router-dom';
import Dashboard from '~/pages/dashboard/Dashboard.tsx';
import Layout from '~/pages/home/Layout.tsx';
import ErrorPage from '~/pages/ErrorPage';
import { useAuth } from '~/hooks';
import { Navigation } from '~/config';
import { useGetConnectedUserQuery } from '~/services/api.ts';
import Loading from '~/components/Loading.tsx';

type Props = object;

function Home(props: Props) {
    const { currentUserHasPermission } = useAuth();
    const { isLoading } = useGetConnectedUserQuery();
    return isLoading ? (
        <Loading />
    ) : (
        <Routes>
            <Route path={'/'} element={<Layout {...props} />}>
                <Route index Component={Dashboard} />
                {Navigation.Routes.filter(
                    (route) => !route.permission || currentUserHasPermission(route.permission),
                ).map((route) => {
                    return <Route key={route.id} Component={route.Component} path={route.path} />;
                })}
                <Route path={'*'} element={<ErrorPage error={'Route not found'} />} />
            </Route>
        </Routes>
    );
}

export default Home;
