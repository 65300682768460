import { Roles } from './constants.ts';
import { Routes } from './navigation.ts';

const Constants = {
    Roles,
};

const Navigation = {
    Routes,
};

export { Constants, Navigation };
