import type { Middleware } from "@reduxjs/toolkit";
import { notification } from "antd";
import { isFulfilled } from '@reduxjs/toolkit/src/matchers.ts';

/**
 * Log a warning and show a toast!
 */
export const successMiddleware: Middleware = () => (next) => (action) => {

    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isFulfilled(action) && action.payload.success) {
        // If we receive any 200 response with success true we do the following.
        notification.success({ message: action.payload.message ?? 'operation successful' });
    }

    return next(action);
};
