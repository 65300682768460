import FsPage from '~/templates/Page/Page.tsx';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import UpdateProfile from '~/pages/settings/pages/UpdateProfile.tsx';
import UpdatePassword from '~/pages/settings/pages/UpdatePassword.tsx';
import ChildWrapper from '~/pages/settings/TabChildWrapper.tsx';

function Settings() {
    const { t } = useTranslation();

    const tabs: {
        label: string;
        key: string;
        children: React.ReactNode;
    }[] = [
        {
            label: t('update_profile'),
            key: 'update_profile',
            children: (
                <ChildWrapper>
                    <UpdateProfile />
                </ChildWrapper>
            ),
        },
        {
            label: t('change_password'),
            key: 'change_password',
            children: (
                <ChildWrapper>
                    <UpdatePassword />
                </ChildWrapper>
            ),
        },
    ];

    return (
        <>
            <FsPage title={t('settings')}>
                <Tabs size={'small'} type={'line'} tabPosition={'left'} items={tabs} />
            </FsPage>
        </>
    );
}

export default Settings;
