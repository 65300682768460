import FsButton from '~/components/buttons/FsButton.tsx';
import { Col, FormInstance, Row } from 'antd';
import { useState } from 'react';

interface Props {
    form: FormInstance;
    name: string;
}

function MonthDaysPicker(props: Props) {
    const [selectedDays, setSelectedDays] = useState<number[]>([]);

    const weekDays = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30, 31,
    ];

    function setDays(value: any, isAdd: boolean) {
        function removeItemOnce(arr: number[], value: any) {
            var index = arr.indexOf(value);
            if (index > -1) {
                arr.splice(index, 1);
            }
            return arr;
        }

        let days = selectedDays;
        if (isAdd) {
            days.push(value);
            console.log('add days: ', days);
            setSelectedDays(days);
            props.form.setFieldValue(props.name, days);
            return;
        }
        days = removeItemOnce(days, value);
        setSelectedDays(days);
        props.form.setFieldValue(props.name, days);
    }

    return (
        <Row gutter={[7, 7]}>
            {weekDays.map((day) => {
                return (
                    <Col key={day} span={1}>
                        <div>
                            <DayBtn
                                onClick={setDays}
                                initSelected={props.form.getFieldValue(props.name)?.includes(day)}
                                value={day}
                            >
                                {day}
                            </DayBtn>
                        </div>
                    </Col>
                );
            })}
        </Row>
    );
}

function DayBtn({ initSelected, onClick, value }: any) {
    const [isSelected, setIsSelected] = useState(initSelected);
    return (
        <FsButton
            onClick={() => {
                onClick(value, !isSelected);
                setIsSelected(!isSelected);
            }}
            type={isSelected ? 'primary' : undefined}
            defaultChecked={true}
            shape={'circle'}
        >
            {value}
        </FsButton>
    );
}

export default MonthDaysPicker;

