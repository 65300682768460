// type Props = {};

import { useTranslation } from 'react-i18next';

import { Button, Col, Form, Input, Layout, Row, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useAuth } from '~/hooks';

const { Title } = Typography;
const { Content } = Layout;

function RestoreAccount() {
    const { t } = useTranslation();
    const { restore } = useAuth();
    const onFinish = (values: { email: string }) => {
        restore(values.email);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <Layout className="layout-default layout-signin">
            <Content className="signin">
                <Row gutter={[24, 0]} justify={"center"}>
                    <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 12 }}>
                        <Row justify={"center"} style={{ margin: "0 auto 50px" }}>
                            <img src={"assets/images/logo.png"} alt="" width={"180px"} />
                        </Row>

                        <Title className="mb-15" level={2}>
                            {t("restore.title")}
                        </Title>
                        <Title className="font-regular text-muted" level={5}>
                            {t("restore.description")}
                        </Title>
                        <Form
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            layout="vertical"
                            className="row-col"
                        >
                            <Form.Item
                                className="username"
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your email!"
                                    }
                                ]}
                            >
                                <Input placeholder="Email" />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{ width: "100%", textTransform: "uppercase" }}
                                >
                                    {t("restore.title")}
                                </Button>
                            </Form.Item>
                            <p className="font-semibold text-muted">
                                <Space>
                                    {t("restore.login_qtn")}
                                    <Link to="/restore" className="text-primary font-bold">
                                        {t("login.title")}
                                    </Link>
                                </Space>
                            </p>
                        </Form>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}

export default RestoreAccount;
