import React, { MutableRefObject, useMemo } from 'react';
import {
    DragAndDrop,
    getWeekLastDate,
    getWeekNumber,
    HeaderRowDirective,
    HeaderRowsDirective,
    Inject,
    Month,
    Resize,
    ResourceDirective,
    ResourcesDirective,
    ScheduleComponent,
    TimelineMonth,
    TimelineViews,
    ViewDirective,
    ViewsDirective,
} from '@syncfusion/ej2-react-schedule';
import { SiteData } from '~/types/querie.ts';
import { Col, Row, Typography } from 'antd';
import './scheduler-light.css';

interface Props {
    dark?: boolean;
    ref: MutableRefObject<any>;
    dataSource?: any[];
    sites: any[];
    hasActionPermission?: boolean;
    onClick: (e: any) => void;
}

const { Title } = Typography;
const resourceHeaderTemplate = (props: { resourceData: SiteData }) => {
    return (
        <Row justify={'center'}>
            <Col>
                <Title level={4}>{props.resourceData?.name}</Title>
            </Col>
        </Row>
    );
};

const Scheduler = (props: Props) => {
    const dateNow = useMemo(() => {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }, []);

    const getWeekDetails = (value: { date: any }) => {
        return 'Week ' + getWeekNumber(getWeekLastDate(value.date, 0));
    };
    const weekTemplate = (props: { date: any }) => {
        return <span className='week'>{getWeekDetails(props)}</span>;
    };

    return (
        <ScheduleComponent
            cssClass='timeline-resource'
            ref={props.ref}
            width='100%'
            rowAutoHeight
            timeScale={{ interval: 60, slotCount: 1 }}
            allowDragAndDrop={false}
            resourceHeaderTemplate={resourceHeaderTemplate}
            selectedDate={dateNow}
            eventSettings={{
                //TODO check mission type with back

                dataSource: props.dataSource,
                fields: {
                    id: 'id',
                    subject: { title: 'Summary', name: 'nb_invoice' },
                    // location: { title: 'Location', name: 'Location' },
                    description: { title: 'Comments', name: 'type' },
                    startTime: { title: 'From', name: 'start_time' },
                    endTime: { title: 'To', name: 'end_time' },
                },
                allowAdding: false,
                allowDeleting: false,
                allowEditing: false,
            }}
            onClick={props.hasActionPermission ? props.onClick : undefined}
            group={{ enableCompactView: true, resources: ['SiteName'] }}
        >
            <ResourcesDirective>
                <ResourceDirective
                    field='site_id'
                    title='SiteName'
                    name='SiteName'
                    allowMultiple={true}
                    dataSource={props.sites}
                    textField='text'
                    idField='id'
                    colorField='color'
                />
            </ResourcesDirective>
            <HeaderRowsDirective>
                <HeaderRowDirective option='Week' template={weekTemplate} />
                <HeaderRowDirective option='Date' />
            </HeaderRowsDirective>
            <ViewsDirective>
                <ViewDirective
                    option='TimelineMonth'
                    interval={2}
                    showWeekend={true}
                    showWeekNumber={true}
                    displayDate={dateNow}
                />
                <ViewDirective option='TimelineWeek' />
                <ViewDirective option='TimelineDay' />
            </ViewsDirective>
            <Inject services={[TimelineViews, Resize, DragAndDrop, TimelineMonth, Month]} />
        </ScheduleComponent>
    );
};

export default Scheduler;
