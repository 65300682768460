import { Switch, SwitchProps } from 'antd';
import { useEffect, useState } from 'react';

interface Props extends SwitchProps {
    lockLoading?: boolean;
}

const FsSwitch = (props: Props) => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        !props.loading && setIsLoading(false);
    }, [props.loading]);

    return (
        <Switch
            {...props}
            loading={!props.lockLoading && isLoading}
            onClick={(checked, arg2) => {
                setIsLoading(true);
                props.onClick && props.onClick(checked, arg2);
            }}
            disabled={props.loading}
        />
    );
};

export default FsSwitch;