import { Outlet } from 'react-router-dom';
import LayoutBuilder from '~/layouts';

function Layout() {
    return (
        <LayoutBuilder>
            <Outlet />
        </LayoutBuilder>
    );
}

export default Layout;
