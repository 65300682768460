import FsFormBuilder from '~/templates/FormGenerator';
import { FormItemProps } from '~/types';

function UpdatePassword() {
    const formItems: FormItemProps[] = [
        {
            name: 'old_password',
            type: 'password',
            label: 'old_password',
            placeholder: 'enter_old_password',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_old_password',
                },
            ],
        },
        {
            name: 'new_password',
            type: 'password',
            label: 'new_password',
            placeholder: 'enter_new_password',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_new_password',
                },
            ],
        },
        {
            name: 'confirm_password',
            type: 'password',
            label: 'confirm_password',
            placeholder: 'confirm_new_password',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_password_confirmation',
                },
            ],
        },
    ];

    return (
        <FsFormBuilder
            submitText={'confirm'}
            items={formItems}
            onSubmit={() => {}}
            loading={false}
        />
    );
}

export default UpdatePassword;