import { Layout, Menu, theme } from 'antd';
import Header from '~/layouts/components/Header.tsx';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuth } from '~/hooks';
import { Navigation } from '~/config';

type Props = {
    children: React.ReactNode;
};
const { Header: AntHeader, Content, Sider } = Layout;
export default function DefaultLayout({ children }: Props) {
    const [collapsed, setCollapsed] = useState(false);
    const { currentUserHasPermission } = useAuth();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Layout
            style={{
                minHeight: '100vh',
            }}
        >
            <Sider
                breakpoint='md'
                collapsedWidth='0'
                trigger={null}
                collapsible
                collapsed={collapsed}
                onBreakpoint={(broken) => {
                    setCollapsed(broken);
                }}
            >
                <Menu
                    style={{
                        height: '100vh',
                        borderRight: 0,
                    }}
                    mode={'inline'}
                    onClick={(e) => {
                        navigate(e.key);
                    }}
                    defaultActiveFirst
                    items={[
                        {
                            key: '/',
                            style: {
                                height: '60px',
                                padding: '14px 24px',
                                fontSize: 'small',
                                fontWeight: 'bold',
                            },
                            icon: (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <img src={'assets/images/logo.png'} alt='' width={35} />
                                </div>
                            ),
                            label: 'Fennec Secure',
                        },

                        ...Navigation.Routes.filter(
                            (route) =>
                                !(
                                    route.permission && !currentUserHasPermission(route.permission)
                                ) && !route.hide,
                        ).map((route) => {
                            return {
                                key: route.path || '',
                                icon: <UserOutlined />,
                                label: t(route.id || ''),
                            };
                        }),
                    ]}
                />
            </Sider>
            <Layout>
                <AntHeader
                    style={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: colorBgContainer,
                    }}
                >
                    <Header
                        collapsed={collapsed}
                        onExpand={() => {
                            setCollapsed(!collapsed);
                        }}
                    />
                </AntHeader>
                <Content
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
    // return (
    //     <Layout className={`layout-dashboard ${!isAdmin ? '' : 'layout-dashboard-admin'}`}>
    //         <Drawer
    //             title={false}
    //             placement={'left'}
    //             closable={false}
    //             onClose={() => setVisible(false)}
    //             open={visible}
    //             key={'left'}
    //             width={250}
    //             className={`drawer-sidebar `}
    //         >
    //             <Layout className={`layout-dashboard`}>
    //                 <Sider
    //                     trigger={null}
    //                     width={250}
    //                     theme='light'
    //                     className={`sider-primary ant-layout-sider-primary`}
    //                 >
    //                     <Sidebar routes={routes || []} />
    //                 </Sider>
    //             </Layout>
    //         </Drawer>
    //         {isAdmin && (
    //             <Sider
    //                 breakpoint='lg'
    //                 collapsedWidth='0'
    //                 onCollapse={(collapsed, type) => {
    //                     console.log(collapsed, type);
    //                 }}
    //                 trigger={null}
    //                 width={250}
    //                 theme='light'
    //                 className={'sider-primary ant-layout-sider-primary'}
    //                 style={{ background: 'transparent' }}
    //             >
    //                 <Sidebar routes={routes || []} />
    //             </Sider>
    //         )}
    //         <Layout>
    //             <AntHeader
    //                 style={{
    //                     position: 'sticky',
    //                     top: 0,
    //                     zIndex: 1,
    //                     width: '100%',
    //                     display: 'flex',
    //                     alignItems: 'center',
    //                 }}
    //             >
    //                 <Header onPress={openDrawer} />
    //             </AntHeader>
    //
    //             <Content className='content-ant'>{children}</Content>
    //             <Footer />
    //         </Layout>
    //     </Layout>
    // );
}
