// import { useNavigate } from 'react-router-dom';
import { enhancedApi } from '~/services/enhancedApi.ts';
import { getSubdomain, setToStorage } from '~/helpers/miscHelper.ts';
import { Permissions, StorageTokenKey } from '~/config/constants.ts';
import { removeAccessToken } from '~/helpers/auth.ts';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


export default function useAuth() {
    const [isFetchingCurrentUser, setIsFetchingCurrentUser] = useState(false);
    const [loginMutation, { isLoading: isLoginLoading }] = enhancedApi.useLoginMutation();
    const [getCurrentUserQuery] = enhancedApi.useLazyGetConnectedUserQuery();
    const { data: currentUser } = enhancedApi.useGetConnectedUserQuery();
    const navigate = useNavigate();


    const getCurrentUser = useCallback(() => {
        setIsFetchingCurrentUser(true);
        getCurrentUserQuery()
            .unwrap()
            .catch(() => {
                navigate("/login");
            })
            .finally(() => {
                setIsFetchingCurrentUser(false);
            });
    }, [getCurrentUserQuery, setIsFetchingCurrentUser, navigate]);

    function currentUserHasPermission(permission: Permissions) {
        return currentUser?.permissions.map((perm) => perm.name).includes(`${permission}`);
    }


    function login(email: string, password: string) {
        const device_name = "WEB_APP_" + getSubdomain();

        loginMutation({
            body: {
                email: email,
                password: password,
                device_name: device_name
            }
        })
            .unwrap()
            .then((response) => {

                setToStorage(StorageTokenKey, response.token);
                // redirect to /
                navigate("/");
                // window.location.pathname = "/";
            })
            .catch((error) => {
                console.log("login error", error);
            });
    }

    function restore(email: string) {
        console.log(email);
        navigate("/login");
    }

    function logout() {
        removeAccessToken();
        navigate("/login");
    }

    useEffect(() => {
        window.location.pathname !== "/login" && getCurrentUser();
    }, [getCurrentUser]);


    return {
        login,
        restore,
        isLoginLoading,
        logout,
        isFetchingCurrentUser,
        currentUserHasPermission
    };
}
