import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

const { Paragraph } = Typography;
const Dashboard = () => {
    const { t } = useTranslation();
    return (
        <>
            <Paragraph>{t('dashboard')}</Paragraph>
        </>
    );
};

export default Dashboard;
