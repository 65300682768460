import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getFromStorage } from '~/helpers/miscHelper.ts';
import { DefaultLang, StorageLangVerb } from '~/config/constants.ts';

i18n

    // Enables the i18next backend
    .use(Backend)

    // Enable automatic language detection
    .use(LanguageDetector)

    // Enables the hook initialization module
    .use(initReactI18next)
    .init({
        lng: getFromStorage(StorageLangVerb) || DefaultLang,
        backend: {
            /* translation file path */
            loadPath: '/locales/{{lng}}.json',
        },
        fallbackLng: DefaultLang,
        debug: import.meta.env.APP_ENV !== 'production',
        keySeparator: false,
        react: {
            useSuspense: true,
        },
        interpolation: {
            escapeValue: false,
            formatSeparator: ',',
        },
    });

export default i18n;
