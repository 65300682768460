import { ObjectAny, RowAction, TableColumn } from '~/types';
import ColumnBuilder from '~/templates/ListView/ColumnBuilder.tsx';
import { Button, Dropdown, Row } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { TFunction } from 'i18next';

interface Props {
    data: ObjectAny[];
    columns: TableColumn[];
    rowActions?: RowAction[];
    t: TFunction;
    onRowClick?: (item: ObjectAny, key?: string) => void;
}

interface row {
    key: string;

    [key: string]: React.ReactNode;
}

const menuProps = (item: ObjectAny, action: RowAction, t: TFunction) => {
    return {
        items: action.listActions
            ?.filter((action) => action.hasPermission)
            .map((listAction) => {
                return {
                    ...listAction,
                    label: t(listAction.label),
                    disabled: listAction.disabled ? listAction.disabled(item) : false,
                };
            }),

        onClick: (e: any) => action.onClick && action.onClick(item, e.key),
    };
};

function rowsBuilder(props: Props): row[] {
    return props.data.map((item, index) => {
        const r: row = { key: index.toString() };

        props.columns.forEach((column, i) => {
            const isLast = props.columns.length - 1 === i;
            r[column.dataIndex] = isLast ? (
                <Row key={index} justify={'space-between'}>
                    <ColumnBuilder columnDesc={column} data={item} />
                    <div>
                        {isLast &&
                            props.rowActions?.map((action, index) => {
                                switch (action.type) {
                                    case 'list':
                                        return (
                                            <Dropdown
                                                key={index}
                                                menu={menuProps(item, action, props.t)}
                                                placement='bottomRight'
                                                dropdownRender={(menu) => <div>{menu}</div>}
                                            >
                                                <Button
                                                    icon={<MoreOutlined />}
                                                    style={{
                                                        border: 'none',
                                                        boxShadow: 'none',
                                                        backgroundColor: 'transparent',
                                                        fontWeight: 'lighter',
                                                    }}
                                                />
                                            </Dropdown>
                                        );

                                    default:
                                        return (
                                            <Button
                                                key={index}
                                                icon={action.icon}
                                                style={{
                                                    border: 'none',
                                                    boxShadow: 'none',
                                                    backgroundColor: 'transparent',
                                                    fontWeight: 'lighter',
                                                }}
                                                danger={action.danger}
                                                onClick={() =>
                                                    action.onClick && action.onClick(item)
                                                }
                                                disabled={
                                                    action.disabled ? action.disabled(item) : false
                                                }
                                            >
                                                {action.label}
                                            </Button>
                                        );
                                }
                            })}
                    </div>
                </Row>
            ) : (
                <ColumnBuilder
                    key={index}
                    columnDesc={column}
                    data={item}
                    onRowClick={props.onRowClick}
                />
            );
        });

        return r;
    });
}

export default rowsBuilder;
