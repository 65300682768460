import FsFormBuilder from '~/templates/FormGenerator';
import FsModal from '~/components/modal';
import { message, ModalProps } from 'antd';
import { enhancedApi } from '~/services/enhancedApi.ts';
import { FormItemProps } from '~/types';
import { useEffect, useState } from 'react';
import { MissionData } from '~/types/querie.ts';
import { getSqlTime, toSqlDateTime } from '~/helpers/miscHelper.ts';
import { MissionTypes } from '~/config/constants.ts';
import { useTranslation } from 'react-i18next';

interface Props extends ModalProps {
    formType: 'ADD' | 'EDIT' | 'ASSIGN';
    onCancel: any;
    selectedMissions?: number[];
    selectedMission?: any;
}

const AddMission = (props: Props) => {
    const { t } = useTranslation();
    const [messageApi] = message.useMessage();
    const { data: sites } = enhancedApi.useGetSitesQuery();
    const { data: agents } = enhancedApi.useGetAgentsQuery({
        body: {
            filters: [],
            page: 1,
            itemsPerPage: 100,
            sortColumn: 'id',
            sortOrder: 'desc',
        },
    });

    const [assignAgentToMission, { isLoading: isAssignAgentLoading }] =
        enhancedApi.useAddAgentToMissionMutation();

    const [addMissionHandler, { isLoading: isCreateLoading }] =
        enhancedApi.useCreateMissionMutation();

    const addMissionFormItems: FormItemProps[] = [
        {
            name: 'type',
            label: 'type',
            placeholder: 'enter_mission_Type',
            type: 'select',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_mission_name',
                },
            ],

            options: MissionTypes?.map((mission) => {
                return {
                    label: mission,
                    value: mission,
                };
            }),
            gridProps: {
                span: 10,
            },
        },
        {
            name: 'site_id',
            label: 'site',
            placeholder: 'enter_mission_site',
            type: 'select',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_mission_client',
                },
            ],
            options: sites?.map((mission) => {
                return {
                    label: mission.name,
                    value: mission.id,
                };
            }),
            gridProps: {
                span: 14,
            },
        },
        {
            name: 'nb_invoice',
            label: 'invoice_nb',
            placeholder: 'enter_mission_nb_invoice',
            type: 'text',
        },
        {
            name: 'start_date',
            label: 'start_date',
            placeholder: 'enter_mission_start_date',
            type: 'date',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_mission_date',
                },
            ],
            gridProps: {
                md: {
                    span: 12,
                },
            },
        },
        {
            name: 'start_hour',
            label: 'start_hour',
            placeholder: 'enter_mission_start_hour',
            type: 'time',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_mission_start_hour',
                },
            ],
            gridProps: {
                md: {
                    span: 12,
                },
            },
        },
        {
            name: 'end_hour',
            label: 'end_hour',
            placeholder: 'enter_mission_end_hour',
            type: 'time',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_mission_end_hour',
                },
            ],
            gridProps: {
                md: {
                    span: 10,
                },
            },
        },
        {
            name: 'recursion',
            label: 'is_recurred',
            placeholder: 'enter_mission_recursion',
            type: 'select',
            options: [
                { label: t('no_recursion'), value: 'NON' },
                { label: t('daily_recursion'), value: 'DAILY' },
                { label: t('weekly_recursion'), value: 'WEEKLY' },
                { label: t('monthly_recursion'), value: 'MONTHLY' },
            ],
            gridProps: {
                md: {
                    span: 14,
                },
            },
        },
    ];

    const [formItems, setFormItems] = useState<FormItemProps[]>(addMissionFormItems);

    useEffect(() => {
        setFormItems(addMissionFormItems);
    }, [sites]);

    const endDateField: FormItemProps = {
        name: 'end_date',
        label: 'end_date',
        placeholder: 'enter_mission_end_date',
        type: 'date',
        gridProps: {
            md: {
                span: 12,
            },
        },
    };

    function setDailyRecursion(recursionType?: 'UNTIL' | 'REPEAT') {
        const fields: FormItemProps[] = [
            {
                name: 'interval',
                label: 'repeat_every_days',
                placeholder: 'enter_mission_repeat_interval',
                type: 'number',
                gridProps: {
                    md: {
                        span: 10,
                    },
                },
            },
            {
                name: 'recursionType',
                label: 'recursion_type',
                placeholder: 'enter_mission_recursion_type',
                type: 'select',
                options: [
                    { label: t('end_date'), value: 'UNTIL' },
                    { label: t('repeat_number'), value: 'REPEAT' },
                ],
                gridProps: {
                    md: {
                        span: 14,
                    },
                },
            },
        ];
        if (recursionType) {
            switch (recursionType) {
                case 'REPEAT':
                    fields.push({
                        name: 'repetition',
                        label: 'repeat_count',
                        placeholder: 'enter_mission_repeat_count',
                        type: 'number',
                        gridProps: {
                            md: {
                                span: 12,
                            },
                        },
                    });
                    break;
                case 'UNTIL':
                    fields.push(endDateField);
                    break;
            }
        }

        setFormItems([...addMissionFormItems, ...fields]);
    }

    function setWeeklyRecursion() {
        const fields: FormItemProps[] = [
            endDateField,
            {
                name: 'weekdays',
                type: 'weekDaysSelector',
                label: 'select_week_days',
                gridProps: {
                    md: {
                        span: 12,
                    },
                },
            },
        ];

        setFormItems([...addMissionFormItems, ...fields]);
    }

    function setMonthlyRecursion() {
        const fields: FormItemProps[] = [
            endDateField,
            {
                name: 'monthlyDays',
                type: 'monthDaysSelector',
                label: 'select_month_days',
                gridProps: {
                    md: {
                        span: 12,
                    },
                },
            },
        ];

        setFormItems([...addMissionFormItems, ...fields]);
    }

    function getFormFields(data: any): MissionData {
        const date = data.start_date.toDate();
        const today = new Date();
        const timezone = today.getTimezoneOffset();
        date.setHours(data.start_hour.hour(), data.start_hour.minute());
        const fields: MissionData = {
            type: data.type,
            site_id: data.site_id,
            nb_invoice: data.nb_invoice,
            start_date: toSqlDateTime(date),
            start_hour: getSqlTime(data.start_hour),
            end_hour: getSqlTime(data.end_hour),
            recurring: true,
            periodic_recurrence: data.recursion,
            timezone: timezone.toString(),
        };

        console.log('data: ', data);
        switch (data.recursion) {
            case 'NON':
                fields.recurring = false;
                fields.periodic_recurrence = null;
                break;
            case 'DAILY':
                fields.recursionType = data.recursionType;
                fields.interval = data.interval;
                if (data.recursionType === 'UNTIL') {
                    fields.end_date = data.end_date
                        ? toSqlDateTime(data.end_date.toDate())
                        : undefined;
                } else {
                    fields.repetition = data.repetition;
                }
                break;
            case 'WEEKLY':
                fields.recursionType = 'UNTIL';
                fields.end_date = data.end_date ? toSqlDateTime(data.end_date.toDate()) : undefined;
                fields.weekdays = data.weekdays;
                break;
            case 'MONTHLY':
                fields.recursionType = 'UNTIL';
                fields.end_date = data.end_date ? toSqlDateTime(data.end_date.toDate()) : undefined;
                fields.monthlyDays = data.monthlyDays;
                break;
        }

        return fields;
    }

    const assignAgentFormItems: FormItemProps[] = [
        {
            name: 'agentIds',
            label: 'agents',
            placeholder: 'enter_assigned_agent',
            type: 'select',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_assigned_agent',
                },
            ],
            selectMode: 'multiple',
            options: agents?.list.map((agent) => {
                return {
                    label: agent.name,
                    value: agent.id,
                };
            }),
        },
        {
            name: 'comment',
            label: 'comment',
            placeholder: 'enter_your_comment',
            type: 'textarea',
        },
    ];

    return (
        <>
            <FsModal {...props}>
                {props.formType === 'ASSIGN' ? (
                    <FsFormBuilder
                        onSubmit={(data: any) => {
                            assignAgentToMission({
                                body: {
                                    ...data,
                                    missionIds: props.selectedMissions,
                                },
                            })
                                .unwrap()
                                .then((response: any) => {
                                    response.message &&
                                        messageApi.success(t('assigneded_successfully'));
                                })
                                .finally(props.onCancel);
                        }}
                        items={assignAgentFormItems}
                        loading={isAssignAgentLoading}
                    />
                ) : (
                    <FsFormBuilder
                        initialValues={props.selectedMission}
                        onSubmit={(data: any) => {
                            addMissionHandler({
                                body: {
                                    ...getFormFields(data),
                                },
                            })
                                .unwrap()
                                .then((response: any) => {
                                    response.message &&
                                        messageApi.success(t('created_successfully'));
                                })
                                .finally(props.onCancel);
                        }}
                        items={formItems}
                        loading={isCreateLoading}
                        onFieldsChange={(fields) => {
                            const recursion = fields.filter(
                                (e) => e.name.length > 0 && e.name[0] === 'recursion',
                            );
                            const recursionType = fields.filter(
                                (e) => e.name.length > 0 && e.name[0] === 'recursionType',
                            );

                            if (recursion.length > 0) {
                                switch (recursion[0].value) {
                                    case 'DAILY':
                                        setDailyRecursion();
                                        break;
                                    case 'WEEKLY':
                                        setWeeklyRecursion();
                                        break;
                                    case 'MONTHLY':
                                        setMonthlyRecursion();
                                        break;
                                    case 'NON':
                                        setFormItems(addMissionFormItems);
                                        break;
                                }
                                return;
                            }
                            if (recursionType.length > 0) {
                                setDailyRecursion(recursionType[0].value);
                            }
                        }}
                    />
                )}
            </FsModal>
        </>
    );
};

export default AddMission;
