import FsListView from '~/templates/ListView';
import { DeleteOutlined, EditOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { DefaultPageSize, DefaultPageSizeOptions, Permissions } from '~/config/constants.ts';
import { message } from 'antd';
import { useState } from 'react';
import FsFormBuilder from '~/templates/FormGenerator';
import { enhancedApi } from '~/services/enhancedApi.ts';

import { useTranslation } from 'react-i18next';
import Loading from '~/components/Loading.tsx';
import { FormItemProps, IServerPagination, ObjectAny, TableColumn } from '~/types';
import { AgentData } from '~/types/querie.ts';
import FsModal from '~/components/modal';
import { showPromiseConfirm } from '~/helpers/confirm.ts';
import { useAuth } from '~/hooks';
import dayjs from 'dayjs';
import { IAgent, useDeleteMultiAgentMutation } from '~/services/api.ts';
import { FilterValue } from 'antd/lib/table/interface';
import { TablePaginationConfig } from 'antd/es/table';
import { toSqlDate } from '~/helpers/miscHelper.ts';

function Agents() {
    const [openAddAgentDrawer, setOpenAddAgentDrawer] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState<any>(null);
    const [selectedAgents, setSelectedAgents] = useState<any[]>([]);
    const [deleteMultiAgentsHandler, { isLoading: isMultiDeleteLoading }] =
        useDeleteMultiAgentMutation();
    const [messageApi, contextHolder] = message.useMessage();
    const [serverPagination, setServerPagination] = useState<IServerPagination>({
        filters: [],
        page: 1,
        search: '',
        itemsPerPage: DefaultPageSize,
        sortColumn: 'id',
        sortOrder: 'desc',
    });
    const { data, isFetching, isLoading } = enhancedApi.useGetAgentsQuery({
        body: serverPagination,
    });
    const { currentUserHasPermission } = useAuth();

    const [updateAgentHandler, { isLoading: isUpdateLoading }] =
        enhancedApi.useUpdateAgentMutation();
    const [addAgentHandler, { isLoading: isCreateLoading }] = enhancedApi.useCreateAgentMutation();
    const [deleteAgentHandler] = enhancedApi.useDeleteAgentMutation();

    const { t } = useTranslation();

    const columns: TableColumn[] = [
        {
            title: 'agent',
            dataIndex: 'name',
            subIndex: 'surname',
            imgIndex: 'img',
            type: 'avatar',
            width: '32',
            sorter: true,
        },
        {
            title: 'status',
            dataIndex: 'accountStatus',
            subIndex: 'statusText',
            type: 'badge',
            filters: [
                {
                    value: 0,
                    text: 'enabled',
                },
                {
                    value: 1,
                    text: 'disabled',
                },
            ],
        },
        {
            title: 'email',
            dataIndex: 'email',
            type: 'text',
        },
        {
            title: 'birthdate',
            dataIndex: 'birthdate',
            type: 'date',
            sorter: true,
        },
        // {
        //     title: 'disabled',
        //     dataIndex: 'disabled',
        //     type: 'switch',
        //     switchData: {
        //         isChecked: (data: ObjectAny) => data.disabled,
        //         loading: isUpdateLoading,
        //         onChange: (checked: any, data: IAgent) => {
        //             const agent: IAgent = data;
        //             updateAgentHandler({
        //                 id: agent.id,
        //                 body: {
        //                     ...agent,
        //                     disabled: checked,
        //                 },
        //             });
        //         },
        //     },
        // },
    ];

    const addAgentFormItems: FormItemProps[] = [
        {
            name: 'name',
            label: 'name',
            placeholder: 'enter_agent_name',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_agent_name',
                },
            ],
            gridProps: {
                md: {
                    span: 14,
                },
            },
        },
        {
            name: 'surname',
            label: 'surname',
            placeholder: 'enter_agent_surname',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_agent_surname',
                },
            ],
            gridProps: {
                md: {
                    span: 10,
                },
            },
        },
        {
            name: 'email',
            label: 'email',
            placeholder: 'enter_agent_email',
            type: 'email',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_agent_email',
                    pattern: new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'),
                },
            ],
            gridProps: {
                md: {
                    span: 12,
                },
            },
        },
        {
            name: 'phone',
            label: 'phone',
            placeholder: 'enter_agent_phone',
            type: 'text',
            rules: [
                {
                    required: false,
                    message: 'validate_enter_agent_phone',
                    // pattern: new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'),
                },
            ],
            gridProps: {
                md: {
                    span: 12,
                },
            },
        },
        {
            name: 'birthdate',
            label: 'birthdate',
            placeholder: 'enter_agent_birthdate',
            type: 'date',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_agent_birthdate',
                },
            ],
        },
        {
            name: 'gender',
            type: 'radio',
            label: 'gender',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_agent_gender',
                },
            ],
            options: [
                {
                    label: t('male'),
                    value: 1,
                },
                {
                    value: 2,
                    label: t('female'),
                },
            ],
        },
        {
            name: 'function',
            label: 'function',
            placeholder: 'enter_admin_function',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_admin_function',
                },
            ],
            gridProps: {
                md: {
                    span: 14,
                },
            },
        },
        {
            name: 'matricule',
            label: 'matricule',
            placeholder: 'enter_admin_matricule',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_admin_matricule',
                },
            ],
            gridProps: {
                md: {
                    span: 10,
                },
            },
        },
        {
            name: 'address',
            label: 'address',
            placeholder: 'enter_admin_address',
            type: 'textarea',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_admin_address',
                },
            ],
        },
        {
            name: 'status',
            label: 'status',
            placeholder: 'enter_admin_status',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_admin_status',
                },
            ],
            gridProps: {
                md: {
                    span: 12,
                },
            },
        },
        {
            name: 'sst',
            label: 'sst',
            placeholder: 'enter_admin_sst',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_admin_sst',
                },
            ],
            gridProps: {
                md: {
                    span: 12,
                },
            },
        },
        {
            name: 'professional_card',
            label: 'professional_card',
            placeholder: 'enter_admin_professional_card',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_admin_professional_card',
                },
            ],
            gridProps: {
                md: {
                    span: 14,
                },
            },
        },
        {
            name: 'mat_speciality',
            label: 'mat_speciality',
            placeholder: 'enter_admin_mat_speciality',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_admin_mat_speciality',
                },
            ],
            gridProps: {
                md: {
                    span: 10,
                },
            },
        },
        {
            name: 'plannable',
            label: 'plannable',
            placeholder: 'enter_admin_mat_plannable',
            type: 'switch',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_admin_plannable',
                },
            ],
            gridProps: {
                span: 12,
            },
        },
        {
            name: 'plan_via_email',
            label: 'plan_via_email',
            placeholder: 'enter_admin_plan_via_email',
            type: 'switch',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_plan_via_email',
                },
            ],
            gridProps: {
                span: 12,
            },
        },
    ];

    // const onPaginationChange = (page: number, pageSize: number) => {
    //     setServerPagination({ ...serverPagination, page: page, itemsPerPage: pageSize });
    // };

    const edit = (item: ObjectAny) => {
        const birthdate = dayjs(item.birthdate);
        setSelectedAgent({ ...item, birthdate });
        setOpenAddAgentDrawer(true);
    };

    const deleteAgent = (id: number) => {
        showPromiseConfirm(
            <ExclamationCircleFilled />,
            t('are_you_sure'),
            t('this_action_can_not_be_undone'),
            () => {
                deleteAgentHandler({ id })
                    .unwrap()
                    .then(() => {
                        messageApi.success(t('deleted_successfully'));
                    });
            },
        );
    };

    const deleteMultiAgents = (ids: number[]) => {
        console.log(ids);
        showPromiseConfirm(
            <ExclamationCircleFilled />,
            t('are_you_sure'),
            t('this_action_can_not_be_undone'),
            () => {
                deleteMultiAgentsHandler({
                    body: {
                        Ids: ids,
                    },
                })
                    .unwrap()
                    .then((response: any) => {
                        response.message && messageApi.success(t('deleted_successfully'));
                    });
            },
        );
    };

    const disableAgent = (agent: IAgent, disable: boolean) => {
        showPromiseConfirm(
            <ExclamationCircleFilled />,
            t('are_you_sure'),
            t('this_action_can_not_be_undone'),
            () => {
                updateAgentHandler({
                    id: agent.id,
                    body: {
                        ...agent,
                        disabled: disable,
                    },
                });
            },
        );
    };

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: any,
    ) => {
        console.log('pagination: ', pagination);
        console.log('sorter: ', sorter);
        setServerPagination({
            ...serverPagination,
            page: pagination.current || 1,
            itemsPerPage: pagination.pageSize || DefaultPageSize,
            sortColumn: sorter.columnKey || 'id',
            sortOrder: sorter.order === 'ascend' ? 'asc' : 'desc',
        });
    };

    function handleMagicSearch(value: string) {
        setServerPagination({
            ...serverPagination,
            search: value,
        });
    }

    return (
        <div>
            {contextHolder}
            <FsModal
                open={openAddAgentDrawer}
                onCancel={() => {
                    setOpenAddAgentDrawer(false);
                    setSelectedAgent(null);
                }}
                title={t(selectedAgent ? 'update_agent' : 'add_agent')}
            >
                <FsFormBuilder
                    initialValues={selectedAgent}
                    onSubmit={(data: AgentData) => {
                        const birthdate = toSqlDate(data.birthdate.toDate());
                        selectedAgent
                            ? updateAgentHandler({
                                  id: selectedAgent.id,
                                  body: { ...data, birthdate },
                              })
                                  .unwrap()
                                  .then((response: any) => {
                                      response.message &&
                                          messageApi.success(t('updated_successfully'));
                                  })
                                  .finally(() => setOpenAddAgentDrawer(false))
                            : addAgentHandler({ body: { ...data, birthdate } })
                                  .unwrap()
                                  .then((response: any) => {
                                      response.message &&
                                          messageApi.success(t('created_successfully'));
                                  })
                                  .finally(() => setOpenAddAgentDrawer(false));
                    }}
                    items={
                        selectedAgent
                            ? addAgentFormItems
                            : [
                                  ...addAgentFormItems,
                                  {
                                      ...selectedAgent,
                                      label: 'password',
                                      type: 'password',
                                      name: 'password',
                                      placeholder: 'enter_agent_password',
                                      rules: [
                                          {
                                              message: 'validate_enter_agent_password',
                                              required: true,
                                              pattern: new RegExp('[A-Za-z\\d@#$%^&+=!*_]{8,}$'),
                                          },
                                      ],
                                  },
                              ]
                    }
                    loading={isCreateLoading || isUpdateLoading}
                />
            </FsModal>
            {isLoading ? (
                <Loading />
            ) : (
                <FsListView
                    setSelectedRows={setSelectedAgents}
                    enableMultiSelect={true}
                    isLoading={isFetching}
                    data={
                        (data &&
                            data.list.map((item) => ({
                                ...item,
                                accountStatus: !item.disabled,
                                statusText: t(item.disabled ? 'disabled' : 'enabled'),
                                img: 'https://picsum.photos/200',
                            }))) ||
                        []
                    }
                    onRowClick={edit}
                    columns={columns}
                    cardTitle={'Agents'}
                    pageActions={[
                        {
                            hasPermission: currentUserHasPermission(Permissions.CreateAgent),
                            label: 'add_agent',
                            btnType: 'primary',
                            onClick: () => {
                                setOpenAddAgentDrawer(true);
                            },
                            viewType: 'button',
                        },
                        {
                            label: 'actions',
                            hasPermission: currentUserHasPermission(Permissions.CreateAdmin),
                            btnType: 'primary',
                            multiSelectAction: true,
                            menuProps: {
                                items: [
                                    {
                                        key: 'delete',
                                        danger: true,
                                        icon: <DeleteOutlined />,
                                        label: t('delete_selected_agents'),
                                        onClick: () => {
                                            deleteMultiAgents(selectedAgents);
                                        },
                                    },
                                ],
                            },
                            viewType: 'list',
                        },
                    ]}
                    rowActions={[
                        {
                            type: 'list',
                            onClick: (item, key) => {
                                switch (key) {
                                    case 'edit': {
                                        edit(item);
                                        break;
                                    }
                                    case 'disable':
                                        disableAgent(item, true);
                                        break;
                                    case 'delete':
                                        deleteAgent(item.id);
                                        break;
                                }
                            },
                            listActions: [
                                {
                                    label: 'edit',
                                    key: 'edit',
                                    hasPermission: currentUserHasPermission(Permissions.EditAgent),
                                    icon: <EditOutlined />,
                                },
                                {
                                    label: 'disable',
                                    danger: true,
                                    hasPermission: currentUserHasPermission(
                                        Permissions.DeleteAgent,
                                    ),
                                    disabled: (data) => data.disabled,
                                    key: 'disable',
                                    icon: <DeleteOutlined />,
                                },
                                {
                                    label: 'delete',
                                    danger: true,
                                    hasPermission: currentUserHasPermission(
                                        Permissions.DeleteAgent,
                                    ),
                                    key: 'delete',
                                    icon: <DeleteOutlined />,
                                },
                            ],
                        },
                    ]}
                    paginationConfig={{
                        position: ['bottomCenter'],
                        defaultPageSize: DefaultPageSize,
                        showSizeChanger: true,
                        pageSizeOptions: DefaultPageSizeOptions,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        total: data?.total || 0,
                        pageSize: serverPagination.itemsPerPage,
                        current: serverPagination.page,
                        // onChange: onPaginationChange,
                    }}
                    onChange={handleTableChange}
                    search={{
                        placeholder: 'search_placeholder',
                        onSearch: (value) => {
                            //TODO backend magic search
                            handleMagicSearch(value);
                        },
                    }}
                />
            )}
        </div>
    );
}

export default Agents;
