import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { enhancedApi } from '~/services/enhancedApi.ts';
import FsFormBuilder from '~/templates/FormGenerator';
import Loading from '~/components/Loading.tsx';
import FsListView from '~/templates/ListView';
import { DeleteOutlined, EditOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { DefaultPageSize, DefaultPageSizeOptions, Permissions } from '~/config/constants.ts';
import FsModal from '~/components/modal';
import { FormItemProps, TableColumn } from '~/types';
import { useAuth } from '~/hooks';
import { message } from 'antd';
import { useDeleteMultiClientMutation } from '~/services/api.ts';
import { showPromiseConfirm } from '~/helpers/confirm.ts';

const Clients = () => {
    const [clients, setClients] = useState<any>([]);
    const [selectedClient, setSelectedClient] = useState<any>(undefined);
    const [selectedClients, setSelectedClients] = useState<any[]>([]);
    const [deleteMultiClientsHandler, { isLoading: isMultiDeleteLoading }] =
        useDeleteMultiClientMutation();
    const [openAddClientDrawer, setOpenAddClientDrawer] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const { currentUserHasPermission } = useAuth();

    const { data, isFetching, isLoading } = enhancedApi.useGetClientsQuery();
    const [updateClientHandler, { isLoading: isUpdateLoading }] =
        enhancedApi.useUpdateClientMutation();
    const [addClientHandler, { isLoading: isCreateLoading }] =
        enhancedApi.useCreateClientMutation();
    const [deleteClientHandler] = enhancedApi.useDeleteClientMutation();

    const { t } = useTranslation();

    useEffect(() => {
        setClients(data);
    }, [data]);

    const buildClientColumns = (): TableColumn[] => [
        {
            title: 'client',
            dataIndex: 'name',
            subIndex: 'surname',
            imgIndex: 'img',
            type: 'avatar',
            width: '32',
        },
        {
            title: 'status',
            dataIndex: 'status',
            subIndex: 'statusText',
            type: 'badge',
            filters: [
                {
                    value: 0,
                    text: 'enabled',
                },
                {
                    value: 1,
                    text: 'disabled',
                },
            ],
        },
        {
            title: 'email',
            dataIndex: 'email',
            type: 'text',
        },
        {
            title: 'country',
            dataIndex: 'country',
            type: 'text',
            filters: [
                {
                    value: 0,
                    text: 'country1',
                },
                {
                    value: 1,
                    text: 'country2',
                },
                {
                    value: 2,
                    text: 'country...',
                },
            ],
        },
        // {
        //     title: 'disabled',
        //     dataIndex: 'disabled',
        //     type: 'switch',
        //     switchData: {
        //         isChecked: (data: ObjectAny) => data.disabled,
        //         loading: isUpdateLoading,
        //         onChange: (checked: any, data: IUser) => {
        //             const admin: IUser = data;
        //             currentUserHasPermission(Permissions.EditAdmin)
        //                 ? updateClientHandler({
        //                       id: admin.id,
        //                       body: {
        //                           ...admin,
        //                           disabled: checked,
        //                       },
        //                   })
        //                 : messageApi.error(t('you_dont_have_permission_to_disable_client'));
        //         },
        //     },
        // },
        {
            title: 'city',
            dataIndex: 'city',
            subIndex: 'postal_code',
            type: 'text',
            filters: [
                {
                    value: 0,
                    text: 'city1',
                },
                {
                    value: 1,
                    text: 'city2',
                },
                {
                    value: 2,
                    text: 'city...',
                },
            ],
        },
    ];

    const addClientFormItems: FormItemProps[] = [
        {
            name: 'name',
            label: 'name',
            placeholder: 'enter_client_name',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_client_name',
                },
            ],
        },
        {
            name: 'email',
            label: 'email',
            placeholder: 'enter_client_email',
            type: 'email',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_client_email',
                    pattern: new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'),
                },
            ],
        },
        {
            name: 'address',
            label: 'address',
            placeholder: 'enter_client_birthdate',
            type: 'textarea',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_client_address',
                },
            ],
        },
        {
            name: 'city',
            type: 'text',
            label: 'city',
            placeholder: 'enter_client_city',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_client_city',
                },
            ],
            gridProps: {
                xs: { span: 24 }, // 24/24
                sm: { span: 12 }, // 12/24
                md: { span: 14 }, // 12/24
            },
        },
        {
            name: 'postal_code',
            type: 'number',
            label: 'postal_code',
            placeholder: 'enter_client_postal_code',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_client_postal_code',
                },
            ],
            gridProps: {
                xs: { span: 24 }, // 24/24
                sm: { span: 12 }, // 12/24
                md: { span: 10 }, // 12/24
            },
        },
        {
            name: 'country',
            type: 'text',
            label: 'country',
            placeholder: 'enter_client_country',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_client_country',
                },
            ],
        },
    ];

    const deleteMultiClients = (ids: number[]) => {
        showPromiseConfirm(
            <ExclamationCircleFilled />,
            t('are_you_sure'),
            t('this_action_can_not_be_undone'),
            () => {
                deleteMultiClientsHandler({
                    body: {
                        Ids: ids,
                    },
                })
                    .unwrap()
                    .then((response: any) => {
                        response.message && messageApi.success(t('deleted_successfully'));
                    });
            },
        );
    };

    return (
        <>
            {contextHolder}
            <FsModal
                open={openAddClientDrawer}
                onCancel={() => {
                    setOpenAddClientDrawer(false);
                    setSelectedClient(undefined);
                }}
                title={t('add_client')}
            >
                <FsFormBuilder
                    onSubmit={(data) => {
                        selectedClient?.id
                            ? updateClientHandler({
                                  id: selectedClient?.id,
                                  body: data,
                              }).finally(() => {
                                  setOpenAddClientDrawer(false);
                              })
                            : addClientHandler({
                                  body: data,
                              }).finally(() => {
                                  setOpenAddClientDrawer(false);
                              });
                    }}
                    initialValues={selectedClient}
                    loading={isCreateLoading || isUpdateLoading}
                    items={
                        selectedClient
                            ? addClientFormItems
                            : [
                                  ...addClientFormItems,
                                  {
                                      name: 'password',
                                      type: 'password',
                                      label: 'password',
                                      placeholder: 'enter_client_password',
                                      rules: [
                                          {
                                              required: true,
                                              message: 'validate_enter_client_password',
                                          },
                                      ],
                                  },
                              ]
                    }
                />
            </FsModal>
            {isLoading ? (
                <Loading />
            ) : (
                <FsListView
                    isLoading={isFetching}
                    data={
                        (clients &&
                            clients.map((item: any) => {
                                const isDisabled = item.isClientDisabled == 0;
                                return {
                                    ...item,
                                    status: isDisabled,
                                    statusText: t(isDisabled ? 'enabled' : 'disabled'),
                                    img: 'https://picsum.photos/200',
                                };
                            })) ||
                        []
                    }
                    columns={buildClientColumns()}
                    cardTitle={'Clients'}
                    enableMultiSelect={true}
                    setSelectedRows={setSelectedClients}
                    pageActions={[
                        {
                            label: 'add_client',
                            btnType: 'primary',
                            hasPermission: currentUserHasPermission(Permissions.CreateClient),
                            onClick: () => {
                                setOpenAddClientDrawer(true);
                            },
                            viewType: 'button',
                        },
                        {
                            label: 'actions',
                            hasPermission: currentUserHasPermission(Permissions.CreateAdmin),
                            btnType: 'primary',
                            multiSelectAction: true,
                            menuProps: {
                                items: [
                                    {
                                        key: 'delete',
                                        danger: true,
                                        icon: <DeleteOutlined />,
                                        label: t('delete_selected_clients'),
                                        onClick: () => {
                                            deleteMultiClients(selectedClients);
                                        },
                                    },
                                ],
                            },
                            viewType: 'list',
                        },
                    ]}
                    rowActions={[
                        {
                            type: 'list',
                            onClick: (item, key) => {
                                switch (key) {
                                    case 'edit':
                                        setSelectedClient(item);
                                        setOpenAddClientDrawer(true);
                                        break;
                                    case 'disable':
                                        deleteClientHandler({
                                            id: item.id,
                                        });
                                        break;
                                }
                            },
                            listActions: [
                                {
                                    label: 'edit',
                                    hasPermission: currentUserHasPermission(Permissions.EditClient),
                                    key: 'edit',
                                    icon: <EditOutlined />,
                                },
                                {
                                    label: 'disable',
                                    hasPermission: currentUserHasPermission(
                                        Permissions.DeleteClient,
                                    ),
                                    disabled: (data) => data.disabled == 1,
                                    danger: true,
                                    key: 'disable',
                                    icon: <DeleteOutlined />,
                                },
                            ],
                        },
                    ]}
                    paginationConfig={{
                        position: ['bottomCenter'],
                        defaultPageSize: DefaultPageSize,
                        showSizeChanger: true,
                        pageSizeOptions: DefaultPageSizeOptions,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        total: clients?.length || 0,
                    }}
                    search={{
                        placeholder: 'search_placeholder',
                        onSearch: (value) => {
                            const res = data?.filter((item: { name: string | string[] }) => {
                                return item.name.includes(value);
                            });
                            setClients(res);
                        },
                    }}
                />
            )}
        </>
    );
};

export default Clients;
