// type Props = {};

import { useTranslation } from 'react-i18next';

import { Col, Layout, Row, Typography } from 'antd';
import FsFormBuilder from '~/templates/FormGenerator';
import { enhancedApi } from '~/services/enhancedApi.ts';
import { getDomain, getSubdomain, setToStorage } from '~/helpers/miscHelper.ts';
import { StorageTokenKey } from '~/config/constants.ts';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';

const { Title } = Typography;
const { Content } = Layout;

function Login() {
    const { t } = useTranslation();
    const [defaultLogin, { isLoading }] = enhancedApi.useLoginMutation();
    const [superLogin] = enhancedApi.useSuperLoginMutation();
    const [clientLogin] = enhancedApi.useClientLoginMutation();


    const onFinish = (values: { email: string; password: string }) => {
        // login(values.email, values.password);
        const domain = getDomain();
        const device_name = "WEB_APP_" + getSubdomain();

        let loginMutation: MutationTrigger<any> = defaultLogin;

        switch (domain) {
        case import.meta.env.VITE_HQ_DOMAIN:
            loginMutation = superLogin;
            break;
        case import.meta.env.VITE_CLIENT_DOMAIN:
            loginMutation = clientLogin;
            break;
        default:
            break;

        }
        loginMutation({
            body: {
                email: values.email,
                password: values.password,
                device_name: device_name
            }
        })
            .unwrap()
            .then((response: any) => {
                setToStorage(StorageTokenKey, response.token);
                window.location.pathname = "/";
            });
    };


    return (
        <Layout style={{
            minHeight: "100vh",
            overflow: "hidden"
        }}>
            <Content>
                <Row gutter={[24, 0]} justify={"center"}>
                    <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6, offset: 0 }} md={{ span: 12 }}>
                        <Row justify={"center"} style={{ margin: "0 auto 50px" }}>
                            <img src={"assets/images/logo.png"} alt="" width={"180px"} />
                        </Row>

                        <Title className="mb-15" level={2}>
                            {t("login.title")}
                        </Title>
                        <Title className="font-regular text-muted" level={5}>
                            {t("login.description")}
                        </Title>
                        <FsFormBuilder items={
                            [
                                {
                                    name: "email",
                                    label: "Email",
                                    rules: [
                                        {
                                            required: true,
                                            message: "Please input your email!",
                                            pattern: new RegExp(
                                                "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"
                                            )
                                        }
                                    ],
                                    placeholder: "Email",
                                    type: "input"

                                },
                                {
                                    name: "password",
                                    label: "Password",
                                    rules: [
                                        {
                                            required: true,
                                            message: "Please input your password!"
                                        }
                                    ],
                                    placeholder: "Password",
                                    type: "password"
                                }
                            ]
                        } onSubmit={onFinish} loading={isLoading} />

                    </Col>
                </Row>
            </Content>

        </Layout>
    );
}

export default Login;
