export const getSubdomain = () => {
    const domainParts = window.location.hostname.split('.');
    if (domainParts.length < 3) {
        // No subdomain exists, return an empty string or default value if needed
        return '';
    }
    return domainParts[0]; // Subdomain will be the first part
};
export const getDomain = () => {
    return window.location.hostname;
};

// get and set token from local storage
export const setToStorage = (key: string, token: string) => {
    localStorage.setItem(key, token);
};
export const getFromStorage = (key: string) => {
    return localStorage.getItem(key);
};

export const getDateAndTime = (data: string) => {
    const date = new Date(data);

    return { date: date.toLocaleDateString('fr-FR'), time: date.toLocaleTimeString('fr-FR') };
};

export function toSqlDateTime(date: Date) {
    const pad = (number: number) => number.toString().padStart(2, '0');

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // getMonth() returns 0-11
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function toSqlDate(date: Date) {
    const pad = (number: number) => number.toString().padStart(2, '0');

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // getMonth() returns 0-11
    const day = pad(date.getDate());

    return `${year}-${month}-${day}`;
}

export function getSqlTime(date: any) {
    return `${date.toDate().getUTCHours().toString().padStart(2, '0')}:${date
        .toDate()
        .getUTCMinutes()
        .toString()
        .padStart(2, '0')}`;
}

export function generateRandomColors(n: number) {
    const colors = [];
    for (let i = 0; i < n; i++) {
        // Generate random RGB values
        const red = Math.floor(Math.random() * 256);
        const green = Math.floor(Math.random() * 256);
        const blue = Math.floor(Math.random() * 256);

        // Convert RGB to Hex
        const color = '#' + ((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1);
        colors.push(color);
    }
    return colors;
}
