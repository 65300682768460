import { useGetConnectedUserQuery } from '~/services/api.ts';
import AgentLayout from '~/layouts/AgentLayout.tsx';
import DefaultLayout from '~/layouts/DefaultLayout.tsx';
import { useEffect, useState } from 'react';
import { Roles } from '~/config/constants.ts';

export type Props = {
    children: React.ReactNode;
};

export default function Layout(props: Props) {
    const { data } = useGetConnectedUserQuery();
    const defaultLayoutUsers = [Roles.Admin, Roles.Sup];

    const [role, setRole] = useState('agent');

    useEffect(() => {
        setRole((r) => data?.roles[0].name || r);
    }, [data]);

    return defaultLayoutUsers.includes(role as Roles) ? (
        <DefaultLayout>{props.children}</DefaultLayout>
    ) : (
        <AgentLayout>{props.children}</AgentLayout>
    );
}
