import { Col, Row } from 'antd';

function ChildWrapper(props: { children: React.ReactNode }) {
    return (
        <Row
            justify={'start'}
            style={{
                marginLeft: 50,
            }}
        >
            <Col span={24} sm={{ span: 24 }} lg={{ span: 18 }} xxl={{ span: 12 }}>
                {props.children}
            </Col>
        </Row>
    );
}

export default ChildWrapper;