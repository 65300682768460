import FsListView from '~/templates/ListView';
import { DeleteOutlined, EditOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { DefaultPageSize, DefaultPageSizeOptions, Permissions } from '~/config/constants.ts';
import { message, Modal } from 'antd';
import { useState } from 'react';
import FsFormBuilder from '~/templates/FormGenerator';
import { enhancedApi } from '~/services/enhancedApi.ts';
import Loading from '~/components/Loading.tsx';
import { useTranslation } from 'react-i18next';
import FsModal from '~/components/modal';
import { ICompany } from '~/services/api.ts';
import { FormItemProps, ObjectAny, TableColumn } from '~/types';
import { useAuth } from '~/hooks';

const { confirm } = Modal;

function Companies() {
    const [openAddCompanyDrawer, setOpenAddCompanyDrawer] = useState(false);
    const { currentUserHasPermission } = useAuth();
    const [updateCompanyHandler, { isLoading: isUpdateLoading }] =
        enhancedApi.useUpdateCompanyMutation();
    const [addCompanyHandler, { isLoading: isCreateLoading }] =
        enhancedApi.useCreateCompanyMutation();
    const [deleteCompanyHandler] = enhancedApi.useDeleteCompanyMutation();
    const { data, isFetching, isLoading } = enhancedApi.useGetCompaniesQuery();
    const [messageApi, contextHolder] = message.useMessage();

    const { t } = useTranslation();

    const columns: TableColumn[] = [
        {
            title: 'COMPANY',
            dataIndex: 'name',
            subIndex: 'email',
            imgIndex: 'img',
            type: 'avatar',
            width: '32',
        },
        {
            title: 'DISABLED',
            dataIndex: 'disabled',
            type: 'switch',
            switchData: {
                isChecked: (data: ObjectAny) => data.disabled,
                loading: isUpdateLoading,
                onChange: (checked: any, data: ICompany) => {
                    const company: ICompany = data;
                    updateCompanyHandler({
                        id: company.id,
                        body: {
                            ...company,
                            disabled: checked,
                        },
                    });
                },
            },
        },
        {
            title: 'CREATED AT',
            dataIndex: 'created_at',
            type: 'date',
        },
    ];

    const addCompanyFormItems: FormItemProps[] = [
        {
            name: 'name',
            label: 'Name',
            placeholder: 'Enter company name',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'Please enter the company name!',
                },
            ],
        },
        {
            name: 'email',
            label: 'Email',
            placeholder: 'Enter company email',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'invalid email address!',
                    pattern: new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'),
                },
            ],
        },
    ];

    const showPromiseConfirm = (id: number) => {
        confirm({
            title: 'Do you want to delete these items?',
            icon: <ExclamationCircleFilled />,
            content: 'When clicked the OK button, this dialog will be closed after 1 second',
            onOk() {
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        return deleteCompanyHandler({ id })
                            .unwrap()
                            .then(() => {
                                messageApi.success(t('deleted_successfully'));
                                resolve(true);
                            })
                            .catch(() => {
                                reject();
                            });
                    }, 1000);
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() {},
        });
    };
    return (
        <div>
            {contextHolder}
            <FsModal
                open={openAddCompanyDrawer}
                onCancel={() => setOpenAddCompanyDrawer(false)}
                title={t('add_company')}
            >
                <FsFormBuilder
                    onSubmit={(data) => addCompanyHandler({ body: data })}
                    loading={isCreateLoading}
                    items={addCompanyFormItems}
                />
            </FsModal>
            {isLoading ? (
                <Loading />
            ) : (
                <FsListView
                    isLoading={isFetching}
                    data={
                        (data &&
                            data.map((item) => ({
                                ...item,
                                img: 'https://picsum.photos/200',
                            }))) ||
                        []
                    }
                    columns={columns}
                    cardTitle={t('Companies')}
                    pageActions={[
                        {
                            label: t('add_company'),
                            btnType: 'primary',
                            onClick: () => {
                                setOpenAddCompanyDrawer(true);
                            },
                            hasPermission: currentUserHasPermission(Permissions.CreateCompany),
                            viewType: 'button',
                        },
                    ]}
                    rowActions={[
                        {
                            type: 'list',
                            onClick: (item, key) => {
                                switch (key) {
                                    case 'edit':
                                        messageApi.success(t('edited_successfully'));
                                        break;
                                    case 'delete':
                                        showPromiseConfirm(item.id);
                                        break;
                                    default:
                                        break;
                                }
                            },
                            listActions: [
                                {
                                    label: 'Edit',
                                    key: 'edit',
                                    hasPermission: currentUserHasPermission(
                                        Permissions.EditCompany,
                                    ),
                                    icon: <EditOutlined />,
                                },
                                {
                                    label: 'Delete',
                                    danger: true,
                                    key: 'delete',
                                    hasPermission: currentUserHasPermission(
                                        Permissions.DeleteCompany,
                                    ),
                                    icon: <DeleteOutlined />,
                                },
                            ],
                        },
                    ]}
                    paginationConfig={{
                        position: ['bottomCenter'],
                        defaultPageSize: DefaultPageSize,
                        showSizeChanger: true,
                        pageSizeOptions: DefaultPageSizeOptions,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        total: data?.length,
                    }}
                    search={{
                        placeholder: t('search_placeholder'),
                        onSearch: (value) => {
                            alert(value);
                        },
                    }}
                />
            )}
        </div>
    );
}

export default Companies;
