import { Typography } from 'antd';

interface Props {
    title: string;
    children: React.ReactNode;
}

function FsPage(props: Props) {
    const { Title } = Typography;
    return (
        <div
            style={{
                padding: 20,
            }}
        >
            <Title
                level={3}
                style={{
                    textTransform: 'capitalize',
                    marginBottom: 50,
                }}
            >
                {props.title}
            </Title>
            {props.children}
        </div>
    );
}

export default FsPage;