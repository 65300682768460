// type Props = {};

import { Badge, Col, Dropdown, Row, Space } from 'antd';
import {
    BellOutlined,
    GlobalOutlined,
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    SettingOutlined,
    TranslationOutlined,
    UserOutlined,
} from '@ant-design/icons';
import FsButton from '~/components/buttons/FsButton.tsx';
import { useNavigate } from 'react-router-dom';
import { SetDarkModeActionVerb, StorageLangVerb, StorageTokenKey } from '~/config/constants.ts';
import { useTranslation } from 'react-i18next';
import FsSwitch from '~/components/buttons/FsSwitch.tsx';
import { useDispatch } from 'react-redux';
import { isDarkMode } from '~/helpers/theme.ts';
import { setToStorage } from '~/helpers/miscHelper.ts';

interface Props {
    // eslint-disable-next-line @typescript-eslint/ban-types
    onExpand: Function;
    collapsed: boolean;
}

function Header(props: Props) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    // const [_, setVisible] = useState(false);
    // const showDrawer = () => setVisible(true);
    const { i18n } = useTranslation();

    const handleLangItemClick = (lang: string) => {
        setToStorage(StorageLangVerb, lang);
        i18n.changeLanguage(lang);
    };

    const dispatch = useDispatch();

    const toggleDarkMode = (isDark: boolean) => {
        dispatch({ type: SetDarkModeActionVerb, payload: isDark });
    };

    return (

        <Row gutter={[24, 0]} justify={"space-between"} style={{ width: "100%" }}>
            <Col>
                <FsButton icon={props.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                          onClick={() => props.onExpand()} transparent noBorder />
            </Col>
            <Space>
                <Col>
                    <FsSwitch onChange={toggleDarkMode} lockLoading checkedChildren={t("dark")}
                              unCheckedChildren={t("light")} defaultChecked={isDarkMode()} />
                </Col>
                <Col>
                    <Dropdown menu={{
                        items: [
                            {
                                label: t("settings"),
                                key: "settings",
                                icon: <SettingOutlined />
                            },
                            {
                                label: t("logout"),
                                key: "logout",
                                icon: <LogoutOutlined />,
                                danger: true
                            }

                        ],
                        onClick: (e) => {
                            switch (e.key) {
                            case "logout":
                                localStorage.removeItem(StorageTokenKey);
                                navigate("/login");
                                break;
                            case "settings":
                                navigate("/settings");
                                break;
                            }
                        }
                    }}
                              placement={"bottomRight"}
                    >
                        <FsButton icon={<UserOutlined />} transparent noBorder />
                    </Dropdown>
                </Col>
                <Col>
                    <Dropdown menu={{
                        items: [
                            {
                                label: "Français",
                                key: "fr",
                                dashed: true,
                                icon: <GlobalOutlined />
                            },
                            {
                                label: "English",
                                key: "en",
                                icon: <GlobalOutlined />
                            }
                        ],
                        onClick: (e) => {
                            handleLangItemClick(e.key);
                        }
                    }}
                              placement={"bottomRight"}
                    >
                        <FsButton icon={<TranslationOutlined />} transparent noBorder />
                    </Dropdown>
                </Col>
                <Col>
                    <FsButton icon={<Badge size="small" count={0}><BellOutlined /> </Badge>} transparent noBorder />
                </Col>
            </Space>

        </Row>

    );
}

export default Header;
