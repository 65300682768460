import FsListView from '~/templates/ListView';
import { DeleteOutlined, EditOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { DefaultPageSize, DefaultPageSizeOptions, Permissions } from '~/config/constants.ts';
import { message } from 'antd';
import { useState } from 'react';
import FsFormBuilder from '~/templates/FormGenerator';
import { enhancedApi } from '~/services/enhancedApi.ts';

import { useTranslation } from 'react-i18next';
import Loading from '~/components/Loading.tsx';
import { FormItemProps, IServerPagination, TableColumn } from '~/types';
import { AdminData } from '~/types/querie.ts';
import FsModal from '~/components/modal';
import { showPromiseConfirm } from '~/helpers/confirm.ts';
import { useAuth } from '~/hooks';
import dayjs from 'dayjs';
import { IAdmin, useDeleteMultiAdminMutation } from '~/services/api.ts';
import { TablePaginationConfig } from 'antd/es/table';
import { FilterValue } from 'antd/lib/table/interface';
import { toSqlDate } from '~/helpers/miscHelper.ts';

function Admins() {
    const [openAddAdminDrawer, setOpenAddAdminDrawer] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState<any>(null);
    const [selectedAdmins, setSelectedAdmins] = useState<any[]>([]);
    const [deleteMultiAdminsHandler, { isLoading: isMultiDeleteLoading }] =
        useDeleteMultiAdminMutation();
    const [serverPagination, setServerPagination] = useState<IServerPagination>({
        filters: [],
        search: '',
        page: 1,
        itemsPerPage: DefaultPageSize,
        sortColumn: 'id',
        sortOrder: 'desc',
    });

    const [messageApi, contextHolder] = message.useMessage();
    const { data, isFetching, isLoading } = enhancedApi.useGetAdminsQuery({
        body: serverPagination,
    });
    const { currentUserHasPermission } = useAuth();

    const [updateAdminHandler, { isLoading: isUpdateLoading }] =
        enhancedApi.useUpdateAdminMutation();
    const [addAdminHandler, { isLoading: isCreateLoading }] = enhancedApi.useCreateAdminMutation();
    const [deleteAdminHandler] = enhancedApi.useDeleteAdminMutation();

    const { t } = useTranslation();

    // function handleSearch(key: string, value: string) {
    //     setServerPagination({
    //         ...serverPagination,
    //
    //         filters: [
    //             ...serverPagination.filters.filter((a) => a.column != key),
    //             {
    //                 column: key,
    //                 search: value,
    //                 filter: 'LIKE',
    //             },
    //         ],
    //     });
    // }

    const columns: TableColumn[] = [
        {
            title: 'admin',
            dataIndex: 'name',
            subIndex: 'surname',
            imgIndex: 'img',
            type: 'avatar',
            width: '32',
            sorter: true,
            filterMode: 'tree',
            filterSearch: true,
            // filterDropdown: (
            //     <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            //         <Input
            //             // ref={searchInput}
            //             placeholder={`Search`}
            //             // value={selectedKeys[0]}
            //             // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            //             onPressEnter={(e) => handleSearch('name', e.)}
            //             style={{ marginBottom: 8, display: 'block' }}
            //         />
            //     </div>
            // ),
        },
        {
            title: 'status',
            dataIndex: 'accountStatus',
            subIndex: 'statusText',
            type: 'badge',
            filters: [
                {
                    value: 0,
                    text: 'enabled',
                },
                {
                    value: 1,
                    text: 'disabled',
                },
            ],
        },
        {
            title: 'email',
            dataIndex: 'email',
            type: 'text',
        },
    ];

    const addAdminFormItems: FormItemProps[] = [
        {
            name: 'name',
            label: 'name',
            placeholder: 'enter_admin_name',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_admin_name',
                },
            ],
            gridProps: {
                md: {
                    span: 14,
                },
            },
        },
        {
            name: 'surname',
            label: 'surname',
            placeholder: 'enter_admin_surname',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_admin_surname',
                },
            ],
            gridProps: {
                md: {
                    span: 10,
                },
            },
        },
        {
            name: 'phone',
            label: 'phone',
            placeholder: 'enter_admin_phone',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_admin_phone',
                },
            ],
            gridProps: {
                md: {
                    span: 12,
                },
            },
        },
        {
            name: 'email',
            label: 'email',
            placeholder: 'enter_admin_email',
            type: 'email',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_admin_email',
                    pattern: new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'),
                },
            ],
            gridProps: {
                md: {
                    span: 12,
                },
            },
        },
        {
            name: 'birthdate',
            label: 'birthdate',
            placeholder: 'enter_admin_birthdate',
            type: 'date',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_admin_birthdate',
                },
            ],
            gridProps: {
                span: 12,
            },
        },
        {
            name: 'gender',
            type: 'radio',
            label: 'gender',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_admin_gender',
                },
            ],
            options: [
                {
                    label: t('male'),
                    value: 1,
                },
                {
                    value: 2,
                    label: t('female'),
                },
            ],
            gridProps: {
                span: 12,
            },
        },
        {
            name: 'function',
            label: 'function',
            placeholder: 'enter_admin_function',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_admin_function',
                },
            ],
            gridProps: {
                md: {
                    span: 14,
                },
            },
        },
        {
            name: 'matricule',
            label: 'matricule',
            placeholder: 'enter_admin_matricule',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_admin_matricule',
                },
            ],
            gridProps: {
                md: {
                    span: 10,
                },
            },
        },
        {
            name: 'address',
            label: 'address',
            placeholder: 'enter_admin_address',
            type: 'textarea',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_admin_address',
                },
            ],
        },
        {
            name: 'status',
            label: 'status',
            placeholder: 'enter_admin_status',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_admin_status',
                },
            ],
            gridProps: {
                md: {
                    span: 12,
                },
            },
        },
        {
            name: 'sst',
            label: 'sst',
            placeholder: 'enter_admin_sst',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_admin_sst',
                },
            ],
            gridProps: {
                md: {
                    span: 12,
                },
            },
        },
        {
            name: 'professional_card',
            label: 'professional_card',
            placeholder: 'enter_admin_professional_card',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_admin_professional_card',
                },
            ],
            gridProps: {
                md: {
                    span: 14,
                },
            },
        },
        {
            name: 'mat_speciality',
            label: 'mat_speciality',
            placeholder: 'enter_admin_mat_speciality',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_admin_mat_speciality',
                },
            ],
            gridProps: {
                md: {
                    span: 10,
                },
            },
        },
        {
            name: 'plannable',
            label: 'plannable',
            placeholder: 'enter_admin_mat_plannable',
            type: 'switch',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_admin_plannable',
                },
            ],
            gridProps: {
                span: 12,
            },
        },
        {
            name: 'plan_via_email',
            label: 'plan_via_email',
            placeholder: 'enter_admin_plan_via_email',
            type: 'switch',
            rules: [
                {
                    required: true,
                    message: 'validate_enter_plan_via_email',
                },
            ],
            gridProps: {
                span: 12,
            },
        },
    ];
    const deleteAdmin = (id: number) => {
        showPromiseConfirm(
            <ExclamationCircleFilled />,
            t('are_you_sure'),
            t('this_action_can_not_be_undone'),
            () => {
                deleteAdminHandler({ id })
                    .unwrap()
                    .then((response: any) => {
                        response.message && messageApi.success(t('deleted_successfully'));
                    });
            },
        );
    };
    const deleteMultiAdmins = (ids: number[]) => {
        showPromiseConfirm(
            <ExclamationCircleFilled />,
            t('are_you_sure'),
            t('this_action_can_not_be_undone'),
            () => {
                deleteMultiAdminsHandler({
                    body: {
                        Ids: ids,
                    },
                })
                    .unwrap()
                    .then((response: any) => {
                        response.message && messageApi.success(t('deleted_successfully'));
                    });
            },
        );
    };
    const disableAdmin = (admin: IAdmin, disable: boolean) => {
        showPromiseConfirm(
            <ExclamationCircleFilled />,
            t('are_you_sure'),
            t('this_action_can_not_be_undone'),
            () => {
                updateAdminHandler({
                    id: admin.id,
                    body: {
                        ...admin,
                        disabled: disable,
                    },
                });
            },
        );
    };

    // const onPaginationChange = (page: number, pageSize: number) => {
    //     setServerPagination({ ...serverPagination, page: page, itemsPerPage: pageSize });
    // };

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: any,
    ) => {
        console.log(filters);
        const filtersKeys = Object.keys(filters);
        setServerPagination({
            ...serverPagination,
            page: pagination.current || 1,
            itemsPerPage: pagination.pageSize || DefaultPageSize,
            sortColumn: sorter.columnKey || 'id',
            sortOrder: sorter.order === 'ascend' ? 'asc' : 'desc',
            filters:
                filtersKeys.length > 0
                    ? filtersKeys.map((key) => {
                          const filter = filters[key];
                          const value = filter ? filter[0] : '';
                          return {
                              column: key === 'accountStatus' ? 'disabled' : key,
                              search: value,
                              filter: 'LIKE',
                          };
                      })
                    : [],
        });
    };

    function handleMagicSearch(value: string) {
        setServerPagination({
            ...serverPagination,
            search: value,
        });
    }

    return (
        <div>
            {contextHolder}
            <FsModal
                open={openAddAdminDrawer}
                onCancel={() => {
                    setOpenAddAdminDrawer(false);
                    setSelectedAdmin(null);
                }}
                title={t(selectedAdmin ? 'update_admin' : 'add_admin')}
            >
                <FsFormBuilder
                    initialValues={selectedAdmin}
                    onSubmit={(data: AdminData) => {
                        const birthdate = toSqlDate(data.birthdate.toDate());
                        selectedAdmin
                            ? currentUserHasPermission(Permissions.EditAdmin)
                                ? updateAdminHandler({
                                      id: selectedAdmin.id,
                                      body: { ...data, birthdate },
                                  })
                                      .unwrap()
                                      .then((response: any) => {
                                          response.message &&
                                              messageApi.success(t('updated_successfully'));
                                      })
                                      .finally(() => setOpenAddAdminDrawer(false))
                                : messageApi.error(t('you_dont_have_permission_to_edit_admin'))
                            : currentUserHasPermission(Permissions.CreateAdmin)
                              ? addAdminHandler({ body: { ...data, birthdate } })
                                    .unwrap()
                                    .then((response: any) => {
                                        response.message &&
                                            messageApi.success(t('created_successfully'));
                                    })
                                    .finally(() => setOpenAddAdminDrawer(false))
                              : messageApi.error(t('you_dont_have_permission_to_create_admin'));
                    }}
                    items={
                        selectedAdmin
                            ? addAdminFormItems
                            : [
                                  ...addAdminFormItems,
                                  {
                                      label: 'password',
                                      type: 'password',
                                      name: 'password',
                                      rules: [
                                          {
                                              message: 'validate_enter_admin_password',
                                              required: true,
                                              pattern: new RegExp('[A-Za-z\\d@#$%^&+=!*_]{8,}$'),
                                          },
                                      ],
                                  },
                              ]
                    }
                    loading={isCreateLoading || isUpdateLoading}
                />
            </FsModal>
            {isLoading ? (
                <Loading />
            ) : (
                <FsListView
                    setSelectedRows={setSelectedAdmins}
                    enableMultiSelect={true}
                    isLoading={isFetching}
                    data={
                        (data &&
                            data.list.map((item: any) => ({
                                ...item,
                                company: item.company.name,
                                accountStatus: item.disabled == 0,
                                statusText: t(item.disabled == 1 ? 'disabled' : 'enabled'),
                                img: 'https://picsum.photos/200',
                            }))) ||
                        []
                    }
                    columns={columns}
                    cardTitle={'Admins'}
                    pageActions={[
                        {
                            label: 'add_admin',
                            hasPermission: currentUserHasPermission(Permissions.CreateAdmin),
                            btnType: 'primary',
                            onClick: () => {
                                setOpenAddAdminDrawer(true);
                            },
                            viewType: 'button',
                        },
                        {
                            label: 'actions',
                            hasPermission: currentUserHasPermission(Permissions.CreateAdmin),
                            btnType: 'primary',
                            multiSelectAction: true,
                            menuProps: {
                                items: [
                                    {
                                        key: 'delete',
                                        danger: true,
                                        icon: <DeleteOutlined />,
                                        label: t('delete_selected_admins'),
                                        onClick: () => {
                                            deleteMultiAdmins(selectedAdmins);
                                        },
                                    },
                                ],
                            },
                            viewType: 'list',
                        },
                    ]}
                    rowActions={[
                        {
                            type: 'list',
                            onClick: (item, key) => {
                                switch (key) {
                                    case 'edit': {
                                        const birthdate = dayjs(item.birthdate);
                                        setSelectedAdmin({ ...item, birthdate });
                                        setOpenAddAdminDrawer(true);
                                        break;
                                    }
                                    case 'disable':
                                        disableAdmin(item, true);
                                        break;
                                    case 'delete':
                                        deleteAdmin(item.id);
                                        break;
                                }
                            },
                            listActions: [
                                {
                                    label: 'edit',
                                    key: 'edit',
                                    hasPermission: currentUserHasPermission(Permissions.EditAdmin),
                                    icon: <EditOutlined />,
                                },
                                {
                                    label: 'disable',
                                    hasPermission: currentUserHasPermission(
                                        Permissions.DeleteAdmin,
                                    ),
                                    disabled: (data) => data.disabled == 1,
                                    danger: true,
                                    key: 'disable',
                                    icon: <DeleteOutlined />,
                                },
                                {
                                    label: 'delete',
                                    hasPermission: currentUserHasPermission(
                                        Permissions.DeleteAdmin,
                                    ),
                                    danger: true,
                                    key: 'delete',
                                    icon: <DeleteOutlined />,
                                },
                            ],
                        },
                    ]}
                    paginationConfig={{
                        position: ['bottomCenter'],
                        defaultPageSize: DefaultPageSize,
                        showSizeChanger: true,
                        pageSizeOptions: DefaultPageSizeOptions,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        total: data?.total || 0,
                        current: serverPagination.page,
                        pageSize: serverPagination.itemsPerPage,
                        // onChange: onPaginationChange,
                    }}
                    onChange={handleTableChange}
                    search={{
                        placeholder: 'search_placeholder',
                        onSearch: (value) => {
                            //TODO frontend search
                            handleMagicSearch(value);
                        },
                    }}
                />
            )}
        </div>
    );
}

export default Admins;
