import { useEffect, useRef, useState } from 'react';
import './index.css';
import { useGetSitesQuery } from '~/services/api.ts';
import { Button, Card, Col, Empty, message, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuth } from '~/hooks';
import { Permissions } from '~/config/constants.ts';
import dayjs from 'dayjs';
import { enhancedApi } from '~/services/enhancedApi.ts';
import AddMission from '~/pages/missions/AddMission.tsx';
import Loading from '~/components/Loading.tsx';
import { generateRandomColors } from '~/helpers/miscHelper.ts';
import Scheduler from '~/pages/planning/scheduler.tsx';

const Planning = () => {
    const scheduleObj = useRef(null);
    const [openAddMissionDrawer, setOpenAddMissionDrawer] = useState(false);
    const [selectedMission, setSelectedMission] = useState<any | undefined>(undefined);
    const [colors, setColors] = useState<string[]>([]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, contextHolder] = message.useMessage();
    const { data: missions, isLoading } = enhancedApi.useGetMissionsQuery();
    const { currentUserHasPermission } = useAuth();

    // const [deleteMissionHandler] = enhancedApi.useDeleteMissionMutation();

    const { t } = useTranslation();
    const { data: sites } = useGetSitesQuery();

    function getDatasetInfo(dataset: { date: string; groupIndex: number }) {
        const site = sites ? sites[dataset.groupIndex] : undefined;
        const startDate = new Date(parseInt(dataset.date));

        return {
            date: startDate,
            site: site,
        };
    }

    useEffect(() => {
        sites && setColors(generateRandomColors(sites.length));
    }, [sites]);

    return isLoading ? (
        <Loading />
    ) : (
        <>
            {contextHolder}
            <AddMission
                open={openAddMissionDrawer}
                onCancel={() => {
                    setOpenAddMissionDrawer(false);
                    setSelectedMission(undefined);
                }}
                title={t(selectedMission ? 'update_mission' : 'add_mission')}
                width={'80%'}
                selectedMission={selectedMission}
                formType={'ADD'}
            />

            <div className='tabled'>
                <Row gutter={24}>
                    <Col xs={24}>
                        <Card
                            bordered={false}
                            className='criclebox tablespace mb-24'
                            title={t('planning')}
                            extra={
                                currentUserHasPermission(Permissions.CreateMission) ? (
                                    <Button
                                        type={'primary'}
                                        className={`tag-primary`}
                                        onClick={() => {
                                            setOpenAddMissionDrawer(true);
                                        }}
                                    >
                                        {t('add_mission')}
                                    </Button>
                                ) : (
                                    <></>
                                )
                            }
                        >
                            {sites && sites.length > 0 ? (
                                <Scheduler
                                    ref={scheduleObj}
                                    dataSource={
                                        missions?.map((mission) => {
                                            const setTime = (timeStr: string, date: Date) => {
                                                const timeComp = timeStr.split(':');
                                                if (timeComp.length > 1) {
                                                    date.setHours(
                                                        parseInt(timeComp[0]),
                                                        parseInt(timeComp[1]),
                                                    );
                                                }
                                            };

                                            const start_time = new Date(mission.start_date);
                                            setTime(mission.start_hour, start_time);

                                            let end_time = new Date(mission.start_date);
                                            setTime(mission.end_hour, end_time);
                                            if (start_time > end_time) {
                                                const date = new Date(
                                                    end_time.setDate(end_time.getDate() + 1),
                                                );
                                                end_time = date;
                                            }
                                            return { ...mission, start_time, end_time };
                                        }) || []
                                    }
                                    dark={false}
                                    sites={sites?.map((site, i) => {
                                        return {
                                            ...site,
                                            color: colors[i],
                                        };
                                    })}
                                    hasActionPermission={currentUserHasPermission(
                                        Permissions.CreateMission,
                                    )}
                                    onClick={(e) => {
                                        e.preventDefault();

                                        const target: any = e.target;
                                        const { cells, dataset } = target;

                                        if (cells) {
                                            const allCells: any[] = Object.values(cells);
                                            let firstCell: any;
                                            let lastCell: any;
                                            allCells?.forEach((cell: any) => {
                                                if (cell.ariaSelected === 'true') {
                                                    if (firstCell) {
                                                        lastCell = cell.dataset;
                                                    } else {
                                                        firstCell = cell.dataset;
                                                    }
                                                } else if (lastCell && firstCell) {
                                                    //
                                                }
                                            });

                                            const { site, date: startDate } =
                                                getDatasetInfo(firstCell);
                                            const { date: endDate } = getDatasetInfo(lastCell);

                                            const end = dayjs(endDate);
                                            const start = dayjs(startDate);

                                            setSelectedMission({
                                                site_id: site?.id,
                                                start_date: start,
                                                start_hour: start,
                                                end_hour: end,
                                            });
                                            setOpenAddMissionDrawer(true);
                                            return;
                                        }

                                        const { site, date } = getDatasetInfo(dataset);
                                        const start_date = dayjs(date);

                                        setSelectedMission({
                                            site_id: site?.id,
                                            start_date: start_date,
                                            start_hour: start_date,
                                        });

                                        setOpenAddMissionDrawer(dataset.date && dataset.groupIndex);
                                    }}
                                />
                            ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )}
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default Planning;
