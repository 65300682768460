import { api } from '~/services/api.ts';

// Function for handling lists
function providesList<R extends { id: string | number }[], T extends string>(
    resultsWithIds: R | undefined,
    tagType: T,
) {
    return resultsWithIds
        ? [
              { type: tagType, id: 'LIST' },
              ...resultsWithIds.map(({ id }) => ({ type: tagType, id: id.toString() })),
          ]
        : [{ type: tagType, id: 'LIST' }];
}

// Function for handling single entities
function providesSingle<T extends string>(result: { id: string | number } | undefined, tagType: T) {
    return result ? [{ type: tagType, id: result.id.toString() }] : [];
}

// Function for handling invalidations
function invalidates<T extends string>(arg: { id: string | number } | undefined, tagType: T) {
    return arg ? [{ type: tagType, id: arg.id.toString() }] : [];
}

// Function for handling invalidation of lists
function invalidatesList<T extends string>(tagType: T) {
    return [{ type: tagType, id: 'LIST' }];
}

export const enhancedApi = api.enhanceEndpoints({
    addTagTypes: ['company', 'admin', 'agent', 'user', 'superuser', 'client', 'mission', 'site'],
    endpoints: {
        getAdmins: {
            providesTags: (result) =>
                providesList(
                    result?.list.map((l: any) => {
                        return { id: l.id };
                    }),
                    'admin',
                ),
        },
        getAdmin: {
            providesTags: (_result, _error, arg) => providesSingle(arg, 'admin'),
        },
        updateAdmin: {
            invalidatesTags: (_result, _error, arg) => invalidates(arg, 'admin'),
        },
        createAdmin: {
            invalidatesTags: () => invalidatesList('admin'),
        },
        deleteAdmin: {
            invalidatesTags: (_result, _error, arg) => [
                ...invalidates(arg, 'admin'),
                ...invalidatesList('admin'),
            ],
        },
        getAgents: {
            providesTags: (result) =>
                providesList(
                    result?.list.map((l: any) => {
                        return { id: l.id };
                    }),
                    'agent',
                ),
        },
        getAgent: {
            providesTags: (_result, _error, arg) => providesSingle(arg, 'agent'),
        },
        deleteAgent: {
            invalidatesTags: (_result, _error, arg) => [
                ...invalidates(arg, 'agent'),
                ...invalidatesList('agent'),
            ],
        },
        createAgent: {
            invalidatesTags: () => invalidatesList('agent'),
        },
        updateAgent: {
            invalidatesTags: (_result, _error, arg) => invalidates(arg, 'agent'),
        },
        getCompanies: {
            providesTags: (result) => providesList(result, 'company'),
        },
        createCompany: {
            invalidatesTags: () => invalidatesList('company'),
        },
        getCompany: {
            providesTags: (_result, _error, arg) => providesSingle(arg, 'company'),
        },
        updateCompany: {
            invalidatesTags: (_result, _error, arg) => invalidates(arg, 'company'),
        },
        deleteCompany: {
            invalidatesTags: (_result, _error, arg) => [
                ...invalidates(arg, 'company'),
                ...invalidatesList('company'),
            ],
        },
        login: {
            invalidatesTags: (result) => invalidates(result?.user, 'user'),
        },
        getUsers: {
            providesTags: (result) => providesList(result, 'user'),
        },
        getConnectedUser: {
            providesTags: (result) => providesSingle(result, 'company'),
        },
        superLogin: {
            invalidatesTags: (result) => invalidates(result?.user, 'superuser'),
        },
        clientLogin: {
            invalidatesTags: (result) => invalidates(result?.user, 'user'),
        },
        createClient: {
            invalidatesTags: () => invalidatesList('client'),
        },
        deleteClient: {
            invalidatesTags: (_result, _error, arg) => [
                ...invalidates(arg, 'client'),
                ...invalidatesList('client'),
            ],
        },
        getClient: {
            providesTags: (_result, _error, arg) => providesSingle(arg, 'client'),
        },
        getClients: {
            providesTags: (result) => providesList(result, 'client'),
        },
        updateClient: {
            invalidatesTags: (_result, _error, arg) => invalidates(arg, 'client'),
        },
        createMission: {
            invalidatesTags: () => invalidatesList('mission'),
        },
        deleteMission: {
            invalidatesTags: (_result, _error, arg) => [
                ...invalidates(arg, 'mission'),
                ...invalidatesList('mission'),
            ],
        },
        addAgentToMission: {
            invalidatesTags: (_result, _error, arg) =>
                arg.body.missionIds.map((id) => {
                    return {
                        type: 'mission',
                        id: id.toString(),
                    };
                }),
        },
        getMission: {
            providesTags: (_result, _error, arg) => providesSingle(arg, 'mission'),
        },
        getMissions: {
            providesTags: (result) => providesList(result, 'mission'),
        },
        updateMission: {
            invalidatesTags: (_result, _error, arg) => invalidates(arg, 'mission'),
        },
        removeAgentFromMission: {
            invalidatesTags: (_result, _error, arg) =>
                invalidates({ id: arg.body.mission_id }, 'mission'),
        },
        getSites: {
            providesTags: (result) => providesList(result, 'site'),
        },
        getSite: {
            providesTags: (_result, _error, arg) => providesSingle(arg, 'site'),
        },
        createSite: {
            invalidatesTags: () => invalidatesList('site'),
        },
        deleteSite: {
            invalidatesTags: (_result, _error, arg) => [
                ...invalidates(arg, 'site'),
                ...invalidatesList('site'),
            ],
        },
        updateSite: {
            invalidatesTags: (_result, _error, arg) => invalidates(arg, 'site'),
        },
    },
});
