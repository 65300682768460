import { baseApi as api } from './baseApi';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAddresses: build.query<GetAddressesApiResponse, GetAddressesApiArg>({
            query: () => ({ url: `/addresses` }),
        }),
        createAddress: build.mutation<CreateAddressApiResponse, CreateAddressApiArg>({
            query: (queryArg) => ({
                url: `/addresses/create`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
        updateAddress: build.mutation<UpdateAddressApiResponse, UpdateAddressApiArg>({
            query: (queryArg) => ({
                url: `/addresses/update/${queryArg.id}`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
        deleteAddress: build.mutation<DeleteAddressApiResponse, DeleteAddressApiArg>({
            query: (queryArg) => ({ url: `/addresses/delete/${queryArg.id}`, method: 'DELETE' }),
        }),
        getAdmins: build.query<GetAdminsApiResponse, GetAdminsApiArg>({
            query: (queryArg) => ({ url: `/admins`, method: 'POST', body: queryArg.body }),
        }),
        getAdmin: build.query<GetAdminApiResponse, GetAdminApiArg>({
            query: (queryArg) => ({ url: `/admins/${queryArg.id}` }),
        }),
        createAdmin: build.mutation<CreateAdminApiResponse, CreateAdminApiArg>({
            query: (queryArg) => ({ url: `/admins/create`, method: 'POST', body: queryArg.body }),
        }),
        updateAdmin: build.mutation<UpdateAdminApiResponse, UpdateAdminApiArg>({
            query: (queryArg) => ({
                url: `/admins/update/${queryArg.id}`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
        deleteAdmin: build.mutation<DeleteAdminApiResponse, DeleteAdminApiArg>({
            query: (queryArg) => ({ url: `/admins/delete/${queryArg.id}`, method: 'DELETE' }),
        }),
        deleteMultiAdmin: build.mutation<DeleteMultiAdminApiResponse, DeleteMultiAdminApiArg>({
            query: (queryArg) => ({
                url: `/admins/multi-delete`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
        getAgents: build.query<GetAgentsApiResponse, GetAgentsApiArg>({
            query: (queryArg) => ({ url: `/agents`, method: 'POST', body: queryArg.body }),
        }),
        getAgent: build.query<GetAgentApiResponse, GetAgentApiArg>({
            query: (queryArg) => ({ url: `/agents/${queryArg.id}` }),
        }),
        createAgent: build.mutation<CreateAgentApiResponse, CreateAgentApiArg>({
            query: (queryArg) => ({ url: `/agents/create`, method: 'POST', body: queryArg.body }),
        }),
        updateAgent: build.mutation<UpdateAgentApiResponse, UpdateAgentApiArg>({
            query: (queryArg) => ({
                url: `/agents/update/${queryArg.id}`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
        deleteAgent: build.mutation<DeleteAgentApiResponse, DeleteAgentApiArg>({
            query: (queryArg) => ({ url: `/agents/delete/${queryArg.id}`, method: 'DELETE' }),
        }),
        deleteMultiAgent: build.mutation<DeleteMultiAgentApiResponse, DeleteMultiAgentApiArg>({
            query: (queryArg) => ({
                url: `/agents/multi-delete`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
        login: build.mutation<LoginApiResponse, LoginApiArg>({
            query: (queryArg) => ({ url: `/login`, method: 'POST', body: queryArg.body }),
        }),
        superLogin: build.mutation<SuperLoginApiResponse, SuperLoginApiArg>({
            query: (queryArg) => ({ url: `/super-login`, method: 'POST', body: queryArg.body }),
        }),
        clientLogin: build.mutation<ClientLoginApiResponse, ClientLoginApiArg>({
            query: (queryArg) => ({ url: `/client-login`, method: 'POST', body: queryArg.body }),
        }),
        getCities: build.query<GetCitiesApiResponse, GetCitiesApiArg>({
            query: () => ({ url: `/cities` }),
        }),
        createCity: build.mutation<CreateCityApiResponse, CreateCityApiArg>({
            query: (queryArg) => ({ url: `/cities/create`, method: 'POST', body: queryArg.body }),
        }),
        updateCity: build.mutation<UpdateCityApiResponse, UpdateCityApiArg>({
            query: (queryArg) => ({
                url: `/cities/update/${queryArg.id}`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
        deleteCity: build.mutation<DeleteCityApiResponse, DeleteCityApiArg>({
            query: (queryArg) => ({ url: `/cities/delete/${queryArg.id}`, method: 'DELETE' }),
        }),
        getClients: build.query<GetClientsApiResponse, GetClientsApiArg>({
            query: () => ({ url: `/clients` }),
        }),
        getClient: build.query<GetClientApiResponse, GetClientApiArg>({
            query: (queryArg) => ({ url: `/clients/${queryArg.id}` }),
        }),
        createClient: build.mutation<CreateClientApiResponse, CreateClientApiArg>({
            query: (queryArg) => ({ url: `/clients/create`, method: 'POST', body: queryArg.body }),
        }),
        updateClient: build.mutation<UpdateClientApiResponse, UpdateClientApiArg>({
            query: (queryArg) => ({
                url: `/clients/update/${queryArg.id}`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
        deleteClient: build.mutation<DeleteClientApiResponse, DeleteClientApiArg>({
            query: (queryArg) => ({ url: `/clients/delete/${queryArg.id}`, method: 'DELETE' }),
        }),
        deleteMultiClient: build.mutation<DeleteMultiClientApiResponse, DeleteMultiClientApiArg>({
            query: (queryArg) => ({
                url: `/clients/multi-delete`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
        getCompanies: build.query<GetCompaniesApiResponse, GetCompaniesApiArg>({
            query: () => ({ url: `/companies` }),
        }),
        createCompany: build.mutation<CreateCompanyApiResponse, CreateCompanyApiArg>({
            query: (queryArg) => ({
                url: `/companies/create`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
        getCompany: build.query<GetCompanyApiResponse, GetCompanyApiArg>({
            query: (queryArg) => ({ url: `/companies/${queryArg.id}` }),
        }),
        updateCompany: build.mutation<UpdateCompanyApiResponse, UpdateCompanyApiArg>({
            query: (queryArg) => ({
                url: `/companies/update/${queryArg.id}`,
                method: 'PUT',
                body: queryArg.body,
            }),
        }),
        deleteCompany: build.mutation<DeleteCompanyApiResponse, DeleteCompanyApiArg>({
            query: (queryArg) => ({ url: `/companies/delete/${queryArg.id}`, method: 'DELETE' }),
        }),
        getCountries: build.query<GetCountriesApiResponse, GetCountriesApiArg>({
            query: () => ({ url: `/countries` }),
        }),
        createCountry: build.mutation<CreateCountryApiResponse, CreateCountryApiArg>({
            query: (queryArg) => ({
                url: `/countries/create`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
        updateCountry: build.mutation<UpdateCountryApiResponse, UpdateCountryApiArg>({
            query: (queryArg) => ({
                url: `/countries/update/${queryArg.id}`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
        deleteCountry: build.mutation<DeleteCountryApiResponse, DeleteCountryApiArg>({
            query: (queryArg) => ({ url: `/countries/delete/${queryArg.id}`, method: 'DELETE' }),
        }),
        getMissions: build.query<GetMissionsApiResponse, GetMissionsApiArg>({
            query: () => ({ url: `/missions` }),
        }),
        getMission: build.query<GetMissionApiResponse, GetMissionApiArg>({
            query: (queryArg) => ({ url: `/missions/${queryArg.id}` }),
        }),
        createMission: build.mutation<CreateMissionApiResponse, CreateMissionApiArg>({
            query: (queryArg) => ({ url: `/missions/create`, method: 'POST', body: queryArg.body }),
        }),
        updateMission: build.mutation<UpdateMissionApiResponse, UpdateMissionApiArg>({
            query: (queryArg) => ({
                url: `/missions/update/${queryArg.id}`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
        deleteMission: build.mutation<DeleteMissionApiResponse, DeleteMissionApiArg>({
            query: (queryArg) => ({ url: `/missions/delete/${queryArg.id}`, method: 'DELETE' }),
        }),
        deleteMultiMission: build.mutation<DeleteMultiMissionApiResponse, DeleteMultiMissionApiArg>(
            {
                query: (queryArg) => ({
                    url: `/missions/multi-delete`,
                    method: 'POST',
                    body: queryArg.body,
                }),
            },
        ),
        addAgentToMission: build.mutation<AddAgentToMissionApiResponse, AddAgentToMissionApiArg>({
            query: (queryArg) => ({
                url: `/missions/add-agent`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
        removeAgentFromMission: build.mutation<
            RemoveAgentFromMissionApiResponse,
            RemoveAgentFromMissionApiArg
        >({
            query: (queryArg) => ({
                url: `/missions/remove-agent`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
        getSites: build.query<GetSitesApiResponse, GetSitesApiArg>({
            query: () => ({ url: `/sites` }),
        }),
        getSite: build.query<GetSiteApiResponse, GetSiteApiArg>({
            query: (queryArg) => ({ url: `/sites/${queryArg.id}` }),
        }),
        createSite: build.mutation<CreateSiteApiResponse, CreateSiteApiArg>({
            query: (queryArg) => ({ url: `/sites/create`, method: 'POST', body: queryArg.body }),
        }),
        updateSite: build.mutation<UpdateSiteApiResponse, UpdateSiteApiArg>({
            query: (queryArg) => ({
                url: `/sites/update/${queryArg.id}`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
        deleteSite: build.mutation<DeleteSiteApiResponse, DeleteSiteApiArg>({
            query: (queryArg) => ({ url: `/sites/delete/${queryArg.id}`, method: 'DELETE' }),
        }),
        deleteMultiSite: build.mutation<DeleteMultiSiteApiResponse, DeleteMultiSiteApiArg>({
            query: (queryArg) => ({
                url: `/sites/multi-delete`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
        getUsers: build.query<GetUsersApiResponse, GetUsersApiArg>({
            query: () => ({ url: `/users` }),
        }),
        getConnectedUser: build.query<GetConnectedUserApiResponse, GetConnectedUserApiArg>({
            query: () => ({ url: `/connected-user` }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as api };
export type GetAddressesApiResponse = /** status 200 Addresses list */ {
    list: {
        id: number;
        country_id: number;
        city_id: number;
        postal_code: string;
        address: string;
        address2: string;
        created_at: string;
        updated_at: string;
    }[];
    total: number;
};
export type GetAddressesApiArg = void;
export type CreateAddressApiResponse = /** status 200 Success */ IAddress;
export type CreateAddressApiArg = {
    body: {
        country_id: number;
        city_id: number;
        postal_code: string;
        address: string;
        address2: string;
    };
};
export type UpdateAddressApiResponse = /** status 200 Success */ IAddress;
export type UpdateAddressApiArg = {
    id: number;
    body: {
        country_id?: number;
        city_id?: number;
        postal_code?: string;
        address?: string;
        address2?: string;
    };
};
export type DeleteAddressApiResponse = /** status 200 Successfully deleted */ boolean;
export type DeleteAddressApiArg = {
    id: number;
};
export type GetAdminsApiResponse = /** status 200 Admins list */ {
    list: {
        id: number;
        name: string;
        surname: string;
        email: string;
        birthdate: string;
        address_id?: number;
        gender: 1 | 2;
        disabled: boolean;
        function?: string | null;
        last_login?: any | null;
        phone?: string | null;
        matricule?: string | null;
        address?: string | null;
        status?: string | null;
        plannable?: boolean;
        professional_card?: string | null;
        sst?: string | null;
        plan_via_email?: boolean;
        mat_speciality?: boolean | null;
    }[];
    total: number;
};
export type GetAdminsApiArg = {
    body: {
        page?: number;
        itemsPerPage?: number;
        sortColumn?: string;
        sortOrder?: string;
        search?: string;
        filters?: {
            column?: string;
            filter?: string;
            search?: string;
        }[];
    };
};
export type GetAdminApiResponse = /** status 200 Success */ IAdmin;
export type GetAdminApiArg = {
    id: number;
};
export type CreateAdminApiResponse = /** status 200 Success */ IAdmin;
export type CreateAdminApiArg = {
    body: {
        name: string;
        surname: string;
        email: string;
        birthdate: string;
        gender: 1 | 2;
        address_id?: number;
        function?: string | null;
        last_login?: any | null;
        phone?: string | null;
        matricule?: string | null;
        address?: string | null;
        status?: string | null;
        plannable?: boolean;
        professional_card?: string | null;
        sst?: string | null;
        plan_via_email?: boolean;
        mat_speciality?: boolean | null;
    };
};
export type UpdateAdminApiResponse = /** status 200 Success */ IAdmin;
export type UpdateAdminApiArg = {
    id: number;
    body: {
        name?: string;
        surname?: string;
        email?: string;
        birthdate?: string;
        gender?: 1 | 2;
        address_id?: number;
        disabled?: boolean;
        function?: string | null;
        last_login?: any | null;
        phone?: string | null;
        matricule?: string | null;
        address?: string | null;
        status?: string | null;
        plannable?: boolean;
        professional_card?: string | null;
        sst?: string | null;
        plan_via_email?: boolean;
        mat_speciality?: boolean | null;
    };
};
export type DeleteAdminApiResponse = /** status 200 Successfully deleted */ boolean;
export type DeleteAdminApiArg = {
    id: number;
};
export type DeleteMultiAdminApiResponse = /** status 200 Successfully deleted */ boolean;
export type DeleteMultiAdminApiArg = {
    body: {
        Ids: number[];
    };
};
export type GetAgentsApiResponse = /** status 200 Agents list */ {
    list: {
        id: number;
        name: string;
        surname: string;
        email: string;
        birthdate: string;
        gender: 1 | 2;
        disabled: boolean;
        created_at?: string;
        function?: string | null;
        last_login?: any | null;
        phone?: string | null;
        matricule?: string | null;
        address?: string | null;
        status?: string | null;
        plannable?: boolean;
        professional_card?: string | null;
        sst?: string | null;
        plan_via_email?: boolean;
        mat_speciality?: boolean | null;
    }[];
    total: number;
};
export type GetAgentsApiArg = {
    body: {
        page?: number;
        itemsPerPage?: number;
        sortColumn?: string;
        sortOrder?: string;
        search?: string;
        filters?: {
            column?: string;
            filter?: string;
            search?: string;
        }[];
    };
};
export type GetAgentApiResponse = /** status 200 Success */ IAgent;
export type GetAgentApiArg = {
    id: number;
};
export type CreateAgentApiResponse = /** status 200 Success */ IAgent;
export type CreateAgentApiArg = {
    body: {
        name: string;
        surname: string;
        email: string;
        birthdate: string;
        gender: 1 | 2;
        function?: string | null;
        last_login?: any | null;
        phone?: string | null;
        matricule?: string | null;
        address?: string | null;
        status?: string | null;
        plannable?: boolean;
        professional_card?: string | null;
        sst?: string | null;
        plan_via_email?: boolean;
        mat_speciality?: boolean | null;
    };
};
export type UpdateAgentApiResponse = /** status 200 Success */ IAgent;
export type UpdateAgentApiArg = {
    id: number;
    body: {
        name?: string;
        surname?: string;
        email?: string;
        birthdate?: string;
        gender?: 1 | 2;
        disabled?: boolean;
        function?: string | null;
        last_login?: any | null;
        phone?: string | null;
        matricule?: string | null;
        address?: string | null;
        status?: string | null;
        plannable?: boolean;
        professional_card?: string | null;
        sst?: string | null;
        plan_via_email?: boolean;
        mat_speciality?: boolean | null;
    };
};
export type DeleteAgentApiResponse = /** status 200 Successfully deleted */ boolean;
export type DeleteAgentApiArg = {
    id: number;
};
export type DeleteMultiAgentApiResponse = /** status 200 Successfully deleted */ boolean;
export type DeleteMultiAgentApiArg = {
    body: {
        Ids: number[];
    };
};
export type LoginApiResponse = /** status 200 Login then return user and token */ {
    user: IUser;
    token: string;
};
export type LoginApiArg = {
    body: {
        email: string;
        password: string;
        device_name: string;
    };
};
export type SuperLoginApiResponse = /** status 200 Login then return user and token */ {
    user: ISuperUser;
    token: string;
};
export type SuperLoginApiArg = {
    body: {
        email: string;
        password: string;
        device_name: string;
    };
};
export type ClientLoginApiResponse = /** status 200 Login then return user and token */ {
    user?: IClientUser;
    token: string;
};
export type ClientLoginApiArg = {
    body: {
        email: string;
        password: string;
        device_name: string;
    };
};
export type GetCitiesApiResponse = /** status 200 Cities list */ {
    list: {
        id: number;
        name: string;
        postal_code: string;
    }[];
    total: number;
};
export type GetCitiesApiArg = void;
export type CreateCityApiResponse = /** status 200 Success */ ICity;
export type CreateCityApiArg = {
    body: {
        name: string;
        postal_code: string;
    };
};
export type UpdateCityApiResponse = /** status 200 Success */ ICity;
export type UpdateCityApiArg = {
    id: number;
    body: {
        name?: string;
        postal_code?: string;
    };
};
export type DeleteCityApiResponse = /** status 200 Successfully deleted */ boolean;
export type DeleteCityApiArg = {
    id: number;
};
export type GetClientsApiResponse = /** status 200 Clients list */ {
    id: number;
    name: string;
    email: string;
    address: string;
    city: string;
    postal_code: string;
    country: string;
    disabled: boolean;
    isClientDisabled?: boolean;
}[];
export type GetClientsApiArg = void;
export type GetClientApiResponse = /** status 200 Success */ IClient;
export type GetClientApiArg = {
    id: number;
};
export type CreateClientApiResponse = /** status 200 Success */ IClient;
export type CreateClientApiArg = {
    body: {
        name: string;
        email: string;
        address: string;
        city: string;
        postal_code: string;
        country: string;
        disabled: boolean;
    };
};
export type UpdateClientApiResponse = /** status 200 Success */ IClient;
export type UpdateClientApiArg = {
    id: number;
    body: {
        name?: string;
        email?: string;
        address?: string;
        city?: string;
        postal_code?: string;
        country?: string;
        disabled?: boolean;
    };
};
export type DeleteClientApiResponse = /** status 200 Successfully deleted */ boolean;
export type DeleteClientApiArg = {
    id: number;
};
export type DeleteMultiClientApiResponse = /** status 200 Successfully deleted */ boolean;
export type DeleteMultiClientApiArg = {
    body: {
        Ids: number[];
    };
};
export type GetCompaniesApiResponse = /** status 200 Companies list */ ICompany[];
export type GetCompaniesApiArg = void;
export type CreateCompanyApiResponse = /** status 200 Success */ ICompany;
export type CreateCompanyApiArg = {
    body: {
        name: string;
        email: string;
        domain: string;
        disabled?: boolean;
    };
};
export type GetCompanyApiResponse = /** status 200 Success */ ICompany;
export type GetCompanyApiArg = {
    id: number;
};
export type UpdateCompanyApiResponse = /** status 200 Success */ ICompany;
export type UpdateCompanyApiArg = {
    id: number;
    body: {
        name?: string;
        email?: string;
        disabled?: boolean;
    };
};
export type DeleteCompanyApiResponse = /** status 200 Successfully deleted */ boolean;
export type DeleteCompanyApiArg = {
    id: number;
};
export type GetCountriesApiResponse = /** status 200 Countries list */ {
    list: {
        id: number;
        name: string;
        created_at: string;
        updated_at: string;
    }[];
    total: number;
};
export type GetCountriesApiArg = void;
export type CreateCountryApiResponse = /** status 200 Success */ ICountry;
export type CreateCountryApiArg = {
    body: {
        name: string;
    };
};
export type UpdateCountryApiResponse = /** status 200 Success */ ICountry;
export type UpdateCountryApiArg = {
    id: number;
    body: {
        name?: string;
    };
};
export type DeleteCountryApiResponse = /** status 200 Successfully deleted */ boolean;
export type DeleteCountryApiArg = {
    id: number;
};
export type GetMissionsApiResponse = /** status 200 Missions list */ {
    id: number;
    type:
        | 'Gardiennage & Surveillance'
        | 'Intervention & Lev\u00E9e de doute'
        | "PC s\u00E9curit\u00E9 & Contr\u00F4le d\\'acc\u00E8s"
        | 'Accueil'
        | 'Gestion administrative';
    nb_invoice: string | null;
    start_date: string;
    end_date: any | null;
    start_hour: string;
    end_hour: string;
    timezone?: string | null;
    color?: string;
    agents?: IAgent[];
}[];
export type GetMissionsApiArg = void;
export type GetMissionApiResponse = /** status 200 Success */ IMission;
export type GetMissionApiArg = {
    id: number;
};
export type CreateMissionApiResponse = /** status 200 Success */ IMission;
export type CreateMissionApiArg = {
    body: {
        site_id?: number;
        color?: string;
        type:
            | 'Gardiennage & Surveillance'
            | 'Intervention & Lev\u00E9e de doute'
            | "PC s\u00E9curit\u00E9 & Contr\u00F4le d\\'acc\u00E8s"
            | 'Accueil'
            | 'Gestion administrative';
        nb_invoice?: string | null;
        start_date: string;
        end_date?: string | null;
        start_hour: string;
        end_hour: string;
        recurring: boolean;
        recursionType?: 'UNTIL' | 'REPEAT';
        timezone?: string;
        interval?: number | null;
        repetition?: number | null;
        weekdays?: number[] | null;
        monthlyDays?: number[] | null;
        periodic_recurrence?: ('WEEKLY' | 'MONTHLY' | 'DAILY') | null;
    };
};
export type UpdateMissionApiResponse = /** status 200 Success */ IMission;
export type UpdateMissionApiArg = {
    id: number;
    body: {
        type?:
            | 'Gardiennage & Surveillance'
            | 'Intervention & Lev\u00E9e de doute'
            | "PC s\u00E9curit\u00E9 & Contr\u00F4le d\\'acc\u00E8s"
            | 'Accueil'
            | 'Gestion administrative';
        nb_invoice?: string | null;
        start_date?: string;
        start_hour?: string;
        end_hour?: string;
        timezone?: string;
        color?: string;
    };
};
export type DeleteMissionApiResponse = /** status 200 Successfully deleted */ boolean;
export type DeleteMissionApiArg = {
    id: number;
};
export type DeleteMultiMissionApiResponse = /** status 200 Successfully deleted */ boolean;
export type DeleteMultiMissionApiArg = {
    body: {
        Ids: number[];
    };
};
export type AddAgentToMissionApiResponse = /** status 200 Success */ IAgentMission;
export type AddAgentToMissionApiArg = {
    body: {
        missionIds: number[];
        agentIds: number[];
        comment?: string;
    };
};
export type RemoveAgentFromMissionApiResponse = /** status 200 Successfully deleted */ boolean;
export type RemoveAgentFromMissionApiArg = {
    body: {
        mission_id: number;
        agentIds: number[];
    };
};
export type GetSitesApiResponse = /** status 200 Sites list */ {
    id: number;
    name: string;
    address: string;
    latitude: string;
    longitude: string;
    comment?: string | null;
    color?: string;
}[];
export type GetSitesApiArg = void;
export type GetSiteApiResponse = /** status 200 Success */ ISite;
export type GetSiteApiArg = {
    id: number;
};
export type CreateSiteApiResponse = /** status 200 Success */ ISite;
export type CreateSiteApiArg = {
    body: {
        client_id: number;
        name: string;
        address: string;
        latitude: string;
        longitude: string;
        comment?: string;
        color?: string;
    };
};
export type UpdateSiteApiResponse = /** status 200 Success */ ISite;
export type UpdateSiteApiArg = {
    id: number;
    body: {
        client_id?: number;
        name?: string;
        address?: string;
        latitude?: string;
        longitude?: string;
        comment?: string;
        color?: string;
    };
};
export type DeleteSiteApiResponse = /** status 200 Successfully deleted */ boolean;
export type DeleteSiteApiArg = {
    id: number;
};
export type DeleteMultiSiteApiResponse = /** status 200 Successfully deleted */ boolean;
export type DeleteMultiSiteApiArg = {
    body: {
        Ids: number[];
    };
};
export type GetUsersApiResponse = /** status 200 Users list */ {
    id: number;
    email: string;
    name: string;
    surname: string;
    fullname?: string;
    roles?: IRole[];
    permissions?: IPermission[];
    disabled: boolean;
}[];
export type GetUsersApiArg = void;
export type GetConnectedUserApiResponse = /** status 200 Connected user */ IUser;
export type GetConnectedUserApiArg = void;
export type IAddress = {
    id: number;
    country_id: number;
    city_id: number;
    postal_code: string;
    address: string;
    address2: string;
    created_at: string;
    updated_at: string;
};
export type IAdmin = {
    id: number;
    name: string;
    user_id: number;
    company_id: number;
    address_id: number;
    surname: string;
    email: string;
    birthdate: string;
    gender: 1 | 2;
    disabled: boolean;
    function?: string | null;
    last_login?: any | null;
    phone?: string | null;
    matricule?: string | null;
    address?: string | null;
    status?: string | null;
    plannable?: boolean;
    professional_card?: string | null;
    sst?: string | null;
    plan_via_email?: boolean;
    mat_speciality?: boolean | null;
    created_at?: string;
    updated_at?: string;
};
export type IAgent = {
    id: number;
    name: string;
    surname: string;
    gender: 1 | 2;
    user_id: number;
    company_id: number;
    email: string;
    birthdate: string;
    disabled: boolean;
    function?: string | null;
    last_login?: any | null;
    phone?: string | null;
    matricule?: string | null;
    address?: string | null;
    status?: string | null;
    plannable?: boolean;
    professional_card?: string | null;
    sst?: string | null;
    plan_via_email?: boolean;
    mat_speciality?: boolean | null;
    created_at?: string;
    updated_at?: string;
};
export type IRole = {
    id: number;
    name: string;
};
export type IPermission = {
    id: number;
    name: string;
};
export type IUser = {
    id: number;
    email: string;
    name: string;
    surname: string;
    fullname: string;
    roles: IRole[];
    permissions: IPermission[];
    disabled: boolean;
    created_at?: string;
    updated_at?: string;
};
export type ISuperUser = {
    id: number;
    company_id?: number;
    email: string;
    name: string;
    surname: string;
    fullname: string;
    roles: IRole[];
    disabled: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
};
export type IClientUser = {
    id: number;
    email: string;
    name: string;
    roles: IRole[];
    disabled: boolean;
    created_at: string;
    updated_at: string;
};
export type ICity = {
    id: number;
    name: string;
    postal_code: string;
    created_at: string;
    updated_at: string;
};
export type IClient = {
    id: number;
    client_user_id?: number;
    name: string;
    email: string;
    address: string;
    city: string;
    postal_code: string;
    country: string;
    disabled: boolean;
    isClientDisabled?: boolean;
    created_at?: string;
    updated_at?: string;
};
export type ICompany = {
    id: number;
    name: string;
    email: string;
    disabled: boolean;
    created_at: string;
    updated_at: string;
    deleted_at?: string;
};
export type ICountry = {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
};
export type IMission = {
    id: number;
    company_id: number;
    client_id: number;
    site_id: number;
    type: string;
    nb_invoice: string;
    start_date: any;
    start_hour: any;
    end_hour: string;
    timezone?: string;
    color?: string;
    created_at?: string;
    updated_at?: string;
    agents?: IAgent[];
};
export type IAgentMission = {
    id: number;
    mission_id: number;
    client_id: number;
    agent_id: number;
    company_id: number;
    site_id?: number;
    comment: string;
    created_at?: string;
    updated_at?: string;
};
export type ISite = {
    id: number;
    client_id: number;
    name: string;
    address: string;
    latitude: string;
    longitude: string;
    comment: string;
    color?: string;
    created_at?: string;
    updated_at?: string;
};
export const {
    useGetAddressesQuery,
    useLazyGetAddressesQuery,
    useCreateAddressMutation,
    useUpdateAddressMutation,
    useDeleteAddressMutation,
    useGetAdminsQuery,
    useLazyGetAdminsQuery,
    useGetAdminQuery,
    useLazyGetAdminQuery,
    useCreateAdminMutation,
    useUpdateAdminMutation,
    useDeleteAdminMutation,
    useDeleteMultiAdminMutation,
    useGetAgentsQuery,
    useLazyGetAgentsQuery,
    useGetAgentQuery,
    useLazyGetAgentQuery,
    useCreateAgentMutation,
    useUpdateAgentMutation,
    useDeleteAgentMutation,
    useDeleteMultiAgentMutation,
    useLoginMutation,
    useSuperLoginMutation,
    useClientLoginMutation,
    useGetCitiesQuery,
    useLazyGetCitiesQuery,
    useCreateCityMutation,
    useUpdateCityMutation,
    useDeleteCityMutation,
    useGetClientsQuery,
    useLazyGetClientsQuery,
    useGetClientQuery,
    useLazyGetClientQuery,
    useCreateClientMutation,
    useUpdateClientMutation,
    useDeleteClientMutation,
    useDeleteMultiClientMutation,
    useGetCompaniesQuery,
    useLazyGetCompaniesQuery,
    useCreateCompanyMutation,
    useGetCompanyQuery,
    useLazyGetCompanyQuery,
    useUpdateCompanyMutation,
    useDeleteCompanyMutation,
    useGetCountriesQuery,
    useLazyGetCountriesQuery,
    useCreateCountryMutation,
    useUpdateCountryMutation,
    useDeleteCountryMutation,
    useGetMissionsQuery,
    useLazyGetMissionsQuery,
    useGetMissionQuery,
    useLazyGetMissionQuery,
    useCreateMissionMutation,
    useUpdateMissionMutation,
    useDeleteMissionMutation,
    useDeleteMultiMissionMutation,
    useAddAgentToMissionMutation,
    useRemoveAgentFromMissionMutation,
    useGetSitesQuery,
    useLazyGetSitesQuery,
    useGetSiteQuery,
    useLazyGetSiteQuery,
    useCreateSiteMutation,
    useUpdateSiteMutation,
    useDeleteSiteMutation,
    useDeleteMultiSiteMutation,
    useGetUsersQuery,
    useLazyGetUsersQuery,
    useGetConnectedUserQuery,
    useLazyGetConnectedUserQuery,
} = injectedRtkApi;
