export enum Roles {
    Sup = 'superAdmin',
    Admin = 'admin',
    Agent = 'agent',
    Client = 'client',
}

export const MissionTypes = [
    'Gardiennage & Surveillance',
    'Intervention & Lev\u00E9e de doute',
    "PC s\u00E9curit\u00E9 & Contr\u00F4le d\\'acc\u00E8s",
    'Accueil',
    'Gestion administrative',
];

export enum Permissions {
    ListUsers = 'list-user',
    ListRoles = 'list-role',
    ListPermissions = 'list-permission',
    ListClients = 'list-client',
    ListAgents = 'list-agent',
    ListAdmins = 'list-admin',
    ListSuperAdmins = 'list-super-admin',
    ListCompanies = 'list-company',
    ListInterventions = 'list-intervention',
    ListSignals = 'list-signal',
    ListSites = 'list-site',
    ListMission = 'list-mission',
    ViewUser = 'view-user',
    ViewRole = 'view-role',
    ViewPermission = 'view-permission',
    ViewClient = 'view-client',
    ViewAgent = 'view-agent',
    ViewAdmin = 'view-admin',
    ViewSuperAdmin = 'view-super-admin',
    ViewCompany = 'view-company',
    ViewSettings = 'view-settings',
    ViewPlanning = 'view-planning',
    ViewIntervention = 'view-intervention',
    ViewSignal = 'view-signal',
    ViewSite = 'view-site',
    ViewMission = 'view-mission',
    CreateUser = 'create-user',
    CreateRole = 'create-role',
    CreatePermission = 'create-permission',
    CreateClient = 'create-client',
    CreateAgent = 'create-agent',
    CreateAdmin = 'create-admin',
    CreateSuperAdmin = 'create-super-admin',
    CreateCompany = 'create-company',
    CreateIntervention = 'create-intervention',
    CreateSignal = 'create-signal',
    CreateSite = 'create-site',
    CreateMission = 'create-mission',
    EditUser = 'edit-user',
    EditRole = 'edit-role',
    EditPermission = 'edit-permission',
    EditClient = 'edit-client',
    EditAgent = 'edit-agent',
    EditAdmin = 'edit-admin',
    EditSuperAdmin = 'edit-super-admin',
    EditCompany = 'edit-company',
    EditSettings = 'edit-settings',
    EditPlanning = 'edit-planning',
    EditIntervention = 'edit-intervention',
    EditSignal = 'edit-signal',
    EditSite = 'edit-site',
    EditMission = 'edit-Mission',
    DeleteUser = 'delete-user',
    DeleteRole = 'delete-role',
    DeletePermission = 'delete-permission',
    DeleteClient = 'delete-client',
    DeleteAgent = 'delete-agent',
    DeleteAdmin = 'delete-admin',
    DeleteSuperAdmin = 'delete-super-admin',
    DeleteCompany = 'delete-company',
    DeleteIntervention = 'delete-intervention',
    DeleteSignal = 'delete-signal',
    DeleteSite = 'delete-site',
    DeleteMission = 'delete-mission',
}

export enum ErrorTypes {
    RouteNotFound = 'Route Not Found',
    Other = 'Undefined Error',
}

export const DefaultPageSize = 10;
export const DefaultPageSizeOptions = ['10', '25', '50', '100'];

export const StorageTokenKey = 'accessToken';
export const StorageThemeMode = 'themeMode';
export const StorageLangVerb = 'lang';
export const DefaultLang = 'en';

export const SetDarkModeActionVerb = 'SET_DARK_MODE';
