import { Route } from "~/types";
import { Permissions } from "./constants";
import Settings from "~/pages/settings";
import Companies from "~/pages/companies";
import Planning from "~/pages/planning";
import Interventions from "~/pages/interventions";
import Signals from "~/pages/signals";
import Agents from "~/pages/agents";
import Clients from "~/pages/clients";
import Admins from "~/pages/admins";
import Sites from "~/pages/sites";
import Missions from "~/pages/missions";

// export const AdminRoles = [Constants.Roles.Admin, Constants.Roles.Sup];

export const Routes: Route[] = [
    {
        id: 'menu.companies',
        path: '/companies',
        Component: Companies,
        permission: Permissions.ListCompanies,
        subs: [
            {
                id: 'menu.company',
                path: '/companies/:id',
                //TODO create company page
                Component: Companies,
                permission: Permissions.ViewCompany,
                hide: true,
            },
        ],
    },
    {
        id: 'menu.sites',
        path: '/sites',
        Component: Sites,
        permission: Permissions.ListSites,
        subs: [
            {
                id: 'menu.site',
                path: '/sites/:id',
                //TODO create site page
                Component: Sites,
                permission: Permissions.ViewCompany,
                hide: true,
            },
        ],
    },
    {
        id: 'menu.missions',
        path: '/missions',
        Component: Missions,
        permission: Permissions.ListMission,
        subs: [
            {
                id: 'menu.mission',
                path: '/missions/:id',
                //TODO create mission page
                Component: Missions,
                permission: Permissions.ViewMission,
                hide: true,
            },
        ],
    },
    {
        id: 'menu.admins',
        path: '/admins',
        Component: Admins,
        permission: Permissions.ListAdmins,
        subs: [
            {
                id: 'menu.admin',
                path: '/admins/:id',
                //TODO create admin page
                Component: Admins,
                permission: Permissions.ViewAdmin,
                hide: true,
            },
        ],
    },
    {
        id: 'menu.clients',
        path: '/clients',
        Component: Clients,
        permission: Permissions.ListClients,
        subs: [
            {
                id: 'menu.client',
                path: '/clients/:id',
                //TODO create client page
                Component: Clients,
                permission: Permissions.ViewClient,
                hide: true,
            },
        ],
    },
    {
        id: 'menu.agents',
        path: '/agents',
        Component: Agents,
        permission: Permissions.ListAgents,
        subs: [
            {
                id: 'menu.agent',
                path: '/agents/:id',
                //TODO create agent page
                Component: Agents,
                permission: Permissions.ViewAgent,
                hide: true,
            },
        ],
    },
    {
        id: 'menu.settings',
        path: '/settings',
        Component: Settings,
        hide: true,
        subs: [],
    },
    {
        id: 'menu.planning',
        path: '/planning',
        Component: Planning,
        // permission: Permissions.ViewPlanning,
        subs: [],
    },
    {
        id: 'menu.interventions',
        path: '/interventions',
        Component: Interventions,
        permission: Permissions.ListInterventions,
        subs: [],
    },
    {
        id: 'menu.signals',
        path: '/signals',
        Component: Signals,
        permission: Permissions.ListSignals,
        subs: [],
    },
];

