import { configureStore } from '@reduxjs/toolkit';
import { api } from '~/services/api.ts';
import { authMiddleware } from '~/middlewares/authMiddleware.ts';
import { errorsMiddleware } from '~/middlewares/errorsMiddleware.ts';
import { SetDarkModeActionVerb, StorageThemeMode } from '~/config/constants.ts';
import { setToStorage } from '~/helpers/miscHelper.ts';
import { isDarkMode } from '~/helpers/theme.ts';
import { successMiddleware } from '~/middlewares/successMiddleware.ts';

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        isDarkMode: (state = isDarkMode(), action) => {
            if (action.type === SetDarkModeActionVerb) {
                setToStorage(StorageThemeMode, action.payload ? 'dark' : 'light');
                return action.payload;
            }
            return state;
        },
    },
    devTools: import.meta.env.APP_ENV !== 'production',

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({}).concat([api.middleware, authMiddleware, errorsMiddleware, successMiddleware]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
