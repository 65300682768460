import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import axios from "axios";
import { getSubdomain } from '~/helpers/miscHelper.ts';
import type { AxiosRequestConfig, AxiosError } from "axios";
import { getToken } from '~/helpers/auth.ts';

const axiosBaseQuery =
    (
        { baseUrl }: { baseUrl: string } = { baseUrl: `${import.meta.env.VITE_SERVER_PROTOCOL}${getSubdomain()}${import.meta.env.VITE_SERVER_DOMAIN}${import.meta.env.VITE_SERVER_API_BASE}`, }
    ): BaseQueryFn<
        {
            url: string;
            method?: AxiosRequestConfig["method"];
            params?: AxiosRequestConfig["params"];
            body?: any;
            headers?: AxiosRequestConfig["headers"];
            noBaseUrl?: boolean;
            responseType?: any;
        },
        unknown,
        unknown
    > =>
        async ({ url, method, params, body, noBaseUrl = false, headers, ...rest }) => {
            //we are using "body" as "data" because of OpenApi generation
            try {
                const authResult = { accessToken: getToken() };
                const axiosConfig: AxiosRequestConfig = {
                    url: (noBaseUrl ? "" : baseUrl) + url,
                    method,
                    data: body,
                    params,
                    headers: {
                        ...headers,
                        Accept: "application/json",
                        authorization: 'Bearer ' + authResult.accessToken,
                        // "X-Expected-Language": 'TODO to be wired with later implementation of Translations',
                    },
                    withCredentials: true,
                    ...rest,
                };

                const result = await axios(axiosConfig);
                return { data: result.data };
            } catch (axiosError) {
                const err = axiosError as AxiosError;
                return {
                    error: {
                        status: err.response?.status,
                        data: err.response?.data || err.message,
                    },
                };
            }
        };

export { axiosBaseQuery };
