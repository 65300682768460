import { Col, Form, FormInstance, Input } from 'antd';
import FsMap from '~/components/map';

interface Props {
    form: FormInstance;
}

function GeoLocator({ form }: Props) {
    // const [lat, setLat] = useState(0);
    // const [lng, setLng] = useState(0);

    function onChange(lat: string, lng: string) {
        lat && form.setFieldValue('latitude', lat.toString());
        lng && form.setFieldValue('longitude', lng.toString());
    }

    return (
        <>
            <Col span={24}>
                <FsMap
                    onChange={onChange}
                    lat={form.getFieldValue('latitude')}
                    lng={form.getFieldValue('longitude')}
                    height={'400px'}
                />
            </Col>
            <Col span={12}>
                <Form.Item
                    name={'latitude'}
                    label={'latitude'}
                    rules={[
                        {
                            required: true,
                            message: 'validate_enter_site_lat',
                        },
                    ]}
                >
                    <Input
                        placeholder={'latitude'}
                        type={'text'}
                        // onChange={(e) => {
                        //     const l = e.target.value;
                        //     setLat(parseInt(l));
                        // }}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name={'longitude'}
                    label={'longitude'}
                    rules={[
                        {
                            required: true,
                            message: 'validate_enter_site_lng',
                        },
                    ]}
                >
                    <Input
                        placeholder={'longitude'}
                        type={'text'}
                        // onChange={(e) => {
                        //     const l = e.target.value;
                        //     setLng(parseInt(l));
                        // }}
                    />
                </Form.Item>
            </Col>
        </>
    );
}

export default GeoLocator;