import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { Middleware } from "@reduxjs/toolkit";

/**
 * Log a warning and show a toast!
 */
export const authMiddleware: Middleware = () => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action) && action.payload.status === 401) {
        // If we receive a 401 (unauthorized) response for any call, we have to login. we let the server take care of that process.
        window.location.href = "/login";
    }

    return next(action);
};
