import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from "~/services/axios";

export const baseApi = createApi({
    baseQuery: axiosBaseQuery(),
    reducerPath: "api",
    endpoints: () => ({}),
    // -- Refresh behavior
    keepUnusedDataFor: 90,
    refetchOnFocus: true,
    refetchOnReconnect: true,
});
