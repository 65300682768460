import { useState } from 'react';
import { message, Typography } from 'antd';
import { enhancedApi } from '~/services/enhancedApi.ts';
import { useAuth } from '~/hooks';
import { useTranslation } from 'react-i18next';
import { TableColumn } from '~/types';
import { DeleteOutlined, EditOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import Loading from '~/components/Loading.tsx';
import FsListView from '~/templates/ListView';
import { DefaultPageSize, DefaultPageSizeOptions, Permissions } from '~/config/constants.ts';
import AddMission from '~/pages/missions/AddMission.tsx';
import { showPromiseConfirm } from '~/helpers/confirm.ts';
import { useDeleteMultiMissionMutation } from '~/services/api.ts';

const { Title } = Typography;

function Missions() {
    const [messageApi, contextHolder] = message.useMessage();
    const [openAddMissionDrawer, setOpenAddMissionDrawer] = useState(false);

    const [selectedMissions, setSelectedMissions] = useState<any[]>([]);

    const [deleteMultiMissionsHandler, { isLoading: isMultiDeleteLoading }] =
        useDeleteMultiMissionMutation();
    const [formType, setFormType] = useState<'ADD' | 'EDIT' | 'ASSIGN'>('ADD');
    const [deleteMissionHandler] = enhancedApi.useDeleteMissionMutation();
    const { data, isFetching, isLoading } = enhancedApi.useGetMissionsQuery();

    const { currentUserHasPermission } = useAuth();

    const { t } = useTranslation();

    const columns: TableColumn[] = [
        {
            title: 'site',
            dataIndex: 'site',
            subIndex: 'surname',
            imgIndex: 'img',
            type: 'avatar',
            width: '32',
        },
        {
            title: 'status',
            dataIndex: 'status',
            subIndex: 'statusText',
            type: 'badge',
            filters: [
                {
                    value: 0,
                    text: 'enabled',
                },
                {
                    value: 1,
                    text: 'disabled',
                },
            ],
        },
        {
            type: 'date',
            title: 'start_date',
            dataIndex: 'start_date',
            subIndex: 'start_hour',
        },
        {
            type: 'custom',
            title: 'working_hours',
            dataIndex: 'data',
            renderer: (data) => {
                return (
                    <div className='ant-employed'>
                        <Title style={{ marginBottom: '0' }} level={5}>
                            {`${data['start_hour']} > ${data['end_hour']}`}
                        </Title>
                    </div>
                );
            },
        },
        {
            type: 'text',
            title: 'invoice_nb',
            dataIndex: 'nb_invoice',
        },
    ];

    const deleteMission = (id: number) => {
        showPromiseConfirm(
            <ExclamationCircleFilled />,
            t('are_you_sure'),
            t('this_action_can_not_be_undone'),
            () => {
                deleteMissionHandler({ id })
                    .unwrap()
                    .then(() => {
                        messageApi.success(t('deleted_successfully'));
                    });
            },
        );
    };
    const deleteMultiMissions = (ids: number[]) => {
        showPromiseConfirm(
            <ExclamationCircleFilled />,
            t('are_you_sure'),
            t('this_action_can_not_be_undone'),
            () => {
                deleteMultiMissionsHandler({
                    body: {
                        Ids: ids,
                    },
                })
                    .unwrap()
                    .then((response: any) => {
                        response.message && messageApi.success(t('deleted_successfully'));
                    });
            },
        );
    };
    const assignMultiMissions = (ids: number[]) => {};
    return (
        <div>
            {contextHolder}
            <AddMission
                open={openAddMissionDrawer}
                onCancel={() => {
                    setOpenAddMissionDrawer(false);
                    setSelectedMissions([]);
                }}
                title={
                    t(selectedMissions.length > 0 ? 'update_mission' : 'add_mission') +
                    (selectedMissions.length > 1)
                        ? `${selectedMissions.length} ${t('missions')}`
                        : ''
                }
                width={formType === 'ASSIGN' ? undefined : '80%'}
                selectedMissions={selectedMissions}
                formType={formType}
            />
            {isLoading ? (
                <Loading />
            ) : (
                <FsListView
                    setSelectedRows={setSelectedMissions}
                    enableMultiSelect={true}
                    isLoading={isFetching}
                    data={
                        (data &&
                            data.map((item: any) => ({
                                ...item,
                                site: item.site.name,
                                status: item.agents.length > 0,
                                statusText: t(item.agents.length > 0 ? 'assigned' : 'not_assigned'),
                                img: 'https://picsum.photos/200',
                            }))) ||
                        []
                    }
                    columns={columns}
                    cardTitle={'Missions'}
                    pageActions={[
                        {
                            hasPermission: currentUserHasPermission(Permissions.CreateMission),
                            label: 'add_mission',
                            btnType: 'primary',
                            onClick: () => {
                                setFormType('ADD');
                                setOpenAddMissionDrawer(true);
                            },
                            viewType: 'button',
                        },

                        {
                            label: 'actions',
                            hasPermission: currentUserHasPermission(Permissions.CreateAdmin),
                            btnType: 'primary',
                            multiSelectAction: true,
                            menuProps: {
                                items: [
                                    {
                                        key: 'assign',
                                        icon: <EditOutlined />,
                                        label: t('assign_selected_missions'),
                                        onClick: () => {
                                            setFormType('ASSIGN');
                                            setOpenAddMissionDrawer(true);
                                        },
                                    },
                                    {
                                        key: 'delete',
                                        danger: true,
                                        icon: <DeleteOutlined />,
                                        label: t('delete_selected_missions'),
                                        onClick: () => {
                                            deleteMultiMissions(selectedMissions);
                                        },
                                    },
                                ],
                            },
                            viewType: 'list',
                        },
                    ]}
                    rowActions={[
                        {
                            type: 'list',
                            onClick: (item, key) => {
                                switch (key) {
                                    case 'delete':
                                        deleteMission(item.id);
                                        break;
                                    case 'assign':
                                        setFormType('ASSIGN');
                                        setSelectedMissions([item.id]);
                                        setOpenAddMissionDrawer(true);
                                        break;
                                }
                            },
                            listActions: [
                                {
                                    label: 'assign_agent',
                                    key: 'assign',
                                    hasPermission: currentUserHasPermission(
                                        Permissions.DeleteMission,
                                    ),
                                    icon: <EditOutlined />,
                                },
                                {
                                    label: 'edit',
                                    key: 'edit',
                                    hasPermission: currentUserHasPermission(
                                        Permissions.EditMission,
                                    ),
                                    icon: <EditOutlined />,
                                },

                                {
                                    label: 'delete',
                                    danger: true,
                                    hasPermission: currentUserHasPermission(
                                        Permissions.DeleteMission,
                                    ),
                                    key: 'delete',
                                    icon: <DeleteOutlined />,
                                },
                            ],
                        },
                    ]}
                    paginationConfig={{
                        position: ['bottomCenter'],
                        defaultPageSize: DefaultPageSize,
                        showSizeChanger: true,
                        pageSizeOptions: DefaultPageSizeOptions,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        total: data?.length || 0,
                    }}
                    search={{
                        placeholder: 'search_placeholder',
                        onSearch: (value) => {
                            //TODO frontend search
                            alert(value);
                        },
                    }}
                />
            )}
        </div>
    );
}

export default Missions;
